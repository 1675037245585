import React from 'react'
import './Iaaswhatwedo.css'
// import { vector } from '../../../../../Assets/Images'
import {iaascapone,iaascaptwo, iaascapthree, iaascapfour, iaascapfive, iaascapsix} from './Iaaspics/Iaaspics'

const Whatwedocyber = () => {
  return (
    <div className='whatiaascontainer'>

      <div className='whatiaasinnerborder'>
        {/* <img className='whatiaasvectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='whatiaasforyour'>
        <br /><br /><h1> What We Do </h1>  <br /><br />
        </div>
      </div>

      <div className='whatiaascardcontainer'>
        <div className='whatiaascard'>
        <img src={iaascapone} alt='iaaswhatone'></img><br/><br/>
          <h2> Custom Solutions </h2>
          <p>
          We recognize that every business is unique. Our solutions are tailor-made to fit your specific requirements, ensuring that you get the most out of your cloud investment.
          </p>
        </div>

        <div className='whatiaascard'>
        <img src={iaascaptwo} alt='iaaswhattwo'></img><br/><br/>
          <h2> Strategic Partnership </h2>
          <p>
          We're not just service providers we're your strategic partners in navigating the complex world of IaaS. Count on us to provide guidance, insights, and recommendations to drive your business forward.
          </p>
        </div>

        <div className='whatiaascard'>
        <img src={iaascapthree} alt='iaaswhatthree'></img><br/><br/>
          <h2> 24/7 Support </h2>
          <p>
          Technical issues can arise at any time. Our dedicated support team is available round the clock to address your concerns and provide timely solutions.
          </p>
        </div>

        <div className='whatiaascard'>
        <img src={iaascapfour} alt='iaaswhatfour'></img><br/><br/>
          <h2> Scalability and Flexibility</h2>
          <p>
          As your business grows, our IaaS solutions grow with you. Scale resources up or down based on demand, giving you the flexibility to adapt to changing needs.
          </p>
        </div>

        <div className='whatiaascard'>
        <img src={iaascapfive} alt='iaaswhatfive'></img><br/><br/>
          <h2> Deployment and Migration </h2>
          <p>
          Seamless deployment and migration are our forte. Our experienced team guides you through the process, minimizing downtime and ensuring a smooth transition to our IaaS platform.
          </p>
        </div>

        <div className='whatiaascard'>
        <img src={iaascapsix} alt='iaaswhatsix'></img><br/><br/>
          <h2> Performance Optimization </h2>
          <p>
          We keep a watchful eye on your resources, ensuring optimal performance. Whether it's fine-tuning virtual machines or optimizing storage, we're dedicated to delivering a superior user experience.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Whatwedocyber