import React from 'react'
import './Adwhatwedo.css'
// import { vector } from '../../../../../Assets/Images'
// import ReadMore from '../../../../ReadMore'
import {mccapone, mccaptwo,mccapthree, mccapfour, mccapfive, mccapsix} from './adpics/Mcpics'

const Aiwhatwedo = () => {
  return (
    <div className='whatcscontainer'>

      <div className='whatcsinnerborder'>
        {/* <img className='whatcsvectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='whatcsforyour1'>
        <br /><br /><br /> <h1> What We Do </h1>  <br /><br /><br />
        </div>
      </div>

      <div className='whatcscardcontainer'>
        <div className='whatcscard'>
        <img src={mccapone} alt='mccapone'></img><br/><br/>
          <h2> Strategic Planning </h2>
          <p>
          We begin with a deep understanding of your business objectives. Our experts collaborate closely to design an application strategy that aligns with your goals.
          </p>
        </div>

        <div className='whatcscard'>
        <img src={mccaptwo} alt='mccaptwo'></img><br/><br/>
          <h2> Tailored Development </h2>
          <p>
          No one-size-fits-all solutions here. We craft applications that are customized to your specific needs, ensuring they cater to your unique challenges and opportunities.
          </p>
        </div>

        <div className='whatcscard'>
        <img src={mccapthree} alt='mccapthree'></img><br/><br/>
          <h2> Agile Development </h2>
          <p>
          We embrace agile methodologies to ensure transparency, collaboration, and rapid development cycles. Your feedback guides the process every step of the way.
          </p>
        </div>

        <div className='whatcscard'>
        <img src={mccapfour} alt='mccapfour'></img><br/><br/>
          <h2> Quality Assurance </h2>
          <p>
          Rigorous testing is in our DNA. We conduct thorough quality assurance to identify and rectify issues, guaranteeing a flawless user experience.
          </p>
        </div>

        <div className='whatcscard'>
        <img src={mccapfive} alt='mccapfive'></img><br/><br/>
          <h2> Development and support </h2>
          <p>
          Our commitment doesn't end with deployment. We provide ongoing support to ensure your applications remain up-to-date, secure, and efficient.
          </p>
        </div>

        <div className='whatcscard'>
        <img src={mccapsix} alt='mccapsix'></img><br/><br/>
          <h2> Innovation Partners </h2>
          <p>
          We're not just developers; we're your innovation partners. Count on us to provide insights, recommendations, and technological guidance to drive your application's success.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Aiwhatwedo