import React from 'react'
import './Entcapabilities.css'
// import { vector } from '../../../../../Assets/Images'
import {picone, pictwo, picthree, picfour, picfive} from '../../Servicespics/Colorpics'

const Entcapabilities = () => {
  return (
    <div className='ent-capcontainer'>

      <div className='ent-capinnerborder'>
        {/* <img className='ent-vectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='ent-capforyour1'>
        <br /><br /><br />  <h1> Capabilities</h1><br /><br /><br />
        </div>
      </div>

      <div className='ent-capcardcontainer'>
        <div className='ent-capcard'>
        <img src={picone} alt='admipicone'/><br/>
          <h2> Expertise and Experience </h2>
          <p>
          Our team brings years of experience and expertise in web and mobile application development, enabling us to deliver high-quality solutions that meet our clients' requirements and exceed their expectations.
          </p>
        </div>

        <div className='ent-capcard'>
        <img src={pictwo} alt='admipictwo'/><br/>
          <h2> Customized Solutions </h2>
          <p>
          We understand that every business is unique, which is why we offer customized solutions tailored to the specific needs, goals, and budget of each client.
          </p>
        </div>

        <div className='ent-capcard'>
        <img src={picthree} alt='admipicthree'/><br/>
          <h2> Collaborative approach </h2>
          <p>
          We believe in fostering open communication and collaboration with our clients throughout the development process, ensuring transparency, alignment, and satisfaction.
          </p>
        </div>

        <div className='ent-capcard'>
        <img src={picfour} alt='admipicfour'/><br/>
          <h2> Quality Assurance </h2>
          <p>
          Quality is at the forefront of everything we do. We adhere to industry best practices and rigorous quality assurance processes to deliver reliable, secure, and bug-free web and mobile applications.
          </p>
        </div>

        <div className='ent-capcard'>
        <img src={picfive} alt='admipicfive'/><br/>
          <h2> Continuous Spport and Maintenance </h2>
          <p>
          Our commitment to our clients doesn't end with project delivery. We provide ongoing support and maintenance services to ensure the continued success and performance of our web and mobile applications.
          </p>
        </div>

        {/* <div className='ent-capcard'>
        <img src={picsix} alt='admipicsix'/><br/>
          <h2> Scalability and Performance</h2>
          <p>
          Enterprise applications need to perform flawlessly even under heavy loads. Our development approach prioritizes scalability and performance optimization to ensure consistent functionality.
          </p>
        </div> */}
      </div>
    </div>
  )
}

export default Entcapabilities