import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Partners.css'
import {checkpoint, forescout, juniper, nagios, qualys} from '../../Assets/partners/Partnersimg'
function Partners() {

    const settings = {
        dots: false,
        infinite: true, 
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 500,
        cssEase: "linear"
      };

  return (
    <div>
        
       
    <div className='partnersimagecontainer1'>
    <div className='partnersheadding'>
            <h1> OUR PARTNERS </h1>
        </div>
        <Slider {...settings}>
        <div className='partnersimage1'>
            <img src={checkpoint} alt='checkpoint'/>
        </div>
        <div className='partnersimage1'>
            <img src={forescout} alt='forescout'/>
        </div>
        <div className='partnersimage1'>
            <img src={juniper} alt='juniper'/>
        </div>
        <div className='partnersimage1'>
            <img src={nagios} alt='nagios'/>
        </div>
        <div className='partnersimage1'>
            <img src={qualys} alt='qualys'/>
        </div>
        </Slider>
    </div>
    </div>
  )
}

export default Partners