import React, {useEffect} from 'react'
import Mlcoverpic from '../Machine Learning/Mlpics/Mlcoverpic.png'
import './Mlearning.css'
import Mlabout from './Mlabout'
import Mlcapabilities from './Mlcapabilities'
import Clients from '../../../../Clients/Clients'
import Mlwhatwedo from './Mlwhatwedo'
import Adddigitrans from '../../Adddigitrans/Adddigitrans'
// import Mltestimonials from '../../../../Svrtestimonials/Svrtestimonials'
import Footer from '../../../../Footer/Footer'
import Herotestimonials from '../../../../Herotestimonials/Herotestimonials'
import Partners from '../../../../../Components/Partners/Partners'



function Mlearning() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);
  return ( 
    <div>
      <div className='mlheader'>
      <img src={Mlcoverpic} alt='Mlcovrepic'/>

      <h1> Machine Learning </h1>
      <p>
      Machine learning also has the potential to improve healthcare,
      by analyzing medical data to identify patterns and predict outcomes,
      and assisting with diagnosis and treatment planning.
      </p> 
      </div>   
      <br/><br/>
      <Mlabout/>
      <br/><br/>
      <Herotestimonials/>
      <br/><br/><br/>
      <Mlcapabilities/>
      <Clients/>
      <Mlwhatwedo/>
      <Adddigitrans/>
      <Partners/>
      {/* <Mltestimonials/> */}
      <br/> <br/> 
      <Footer/>
    </div>
  )
}

export default Mlearning

