import React from 'react'
import './Cmcapabilities.css'
// import { vector } from '../../../../../Assets/Images'
import ReadMore from '../../../../ReadMore'
import {picone, pictwo, picthree, picfour, picfive, picsix} from '../../Servicespics/Colorpics'

const Cmcapabilities = () => {
  return (
    <div className='container'>

      <div className='innerborder'>
        {/* <img className='vectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='foryour1'>
        <br /><br /><br /><h1> Capabilities</h1><br /><br /><br />
        </div>
      </div>

      <div className='cardcontainer'>
        <div className='card'>
        <img src={picone} alt='mccapone'/><br/>
          <h2> Cloud Strategy and Consulting </h2>
          <ReadMore className='RMore1'
            text = {'Our expert consultants work closely with your team to understand your business objectives and design a comprehensive cloud strategy. Whether you are looking to migrate existing applications or build new cloud-native solutions, we have got you covered.'}
            maxLines={4}/>
            </div>

        <div className='card'>
        <img src={pictwo} alt='mccaptwo'/><br/>
          <h2> Migration Services </h2>
          <ReadMore className='RMore1'
            text = {'Transitioning to the cloud does not have to be overwhelming. Our migration services facilitate a seamless move from on-premises environments to the cloud, minimizing downtime and ensuring data integrity throughout the process.'}
            maxLines={4}/>
        </div>

        <div className='card'>
        <img src={picthree} alt='mccapthree'/><br/>
          <h2> Multi-Cloud Management </h2>
          <ReadMore className='RMore1'
            text ={'We understand that different workloads may perform better on different cloud platforms. Our multi-cloud management approach allows us to optimize your applications for various cloud providers, maximizing performance and flexibility.'}
          maxLines={4}/>
        </div>

        <div className='card'>
        <img src={picfour} alt='mccapfour'/><br/>
          <h2> Infrastructure Management </h2>
          <ReadMore className='RMore1'
            text = {'Leave the complexities of cloud infrastructure to us. We handle provisioning, monitoring, scaling, and ensuring high availability, so you can focus on what you do best—growing your business.'}
            maxLines={4}/>
        </div>

        <div className='card'>
        <img src={picfive} alt='mccapfive'/><br/>
          <h2> Cost Optimization </h2>
          <ReadMore className='RMore1'
          text={'Cloud resources can quickly become expensive without proper management. We analyze your cloud usage, identify cost-saving opportunities, and implement strategies to eliminate waste while maintaining performance.'}
          maxLines={4}/>
        </div>

        <div className='card'>
        <img src={picsix} alt='mccapsix'/><br/>
          <h2> Security and Compliance </h2>
          <ReadMore className='RMore1'
           text={'Safeguarding your data is paramount. We implement industry best practices to secure your cloud environment and ensure compliance with relevant regulations, giving you peace of mind.'}
          maxLines={4}/>
        </div>
      </div>
    </div>
  )
}

export default Cmcapabilities