import React from 'react'
import './Infracapabilities.css'
// import { vector } from '../../../../../Assets/Images'
import {picone, pictwo, picthree, picfour, picfive} from '../../Servicespics/Colorpics'
import ReadMore from '../../../../ReadMore'

const Infracapabilities = () => {
  return (
    <div className='entcapcontainer'>

      <div className='entcapinnerborder'>
        {/* <img className='entvectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='entcapforyour1'>
        <br /><br /><br />  <h1> Capabilities</h1><br /><br /><br />
        </div>
      </div>

      <div className='entcapcardcontainer'>
        <div className='entcapcard'>
          <img src={picone} alt='admipicone'/><br/>
          <h2> Proven Track Record </h2>
          <ReadMore className='text'
          text={'Our team comprises certified security professionals with extensive experience in managing and securing diverse network environments. We have a proven track record of delivering reliable and effective Managed Network Security Services to organizations of all sizes and industries.'}
          maxLines={3}/>
        </div>

        <div className='entcapcard'>
        <img src={pictwo} alt='admipictwo'/><br/>
          <h2> Customer-Centric Approach </h2>
          <ReadMore className='text'
          text={'We prioritize customer satisfaction and strive to exceed your expectations by delivering personalized service, support, and solutions. We tailor our Managed Network Security Services to meet your specific security requirements, business objectives, and compliance needs.'}
          maxLines={3}/>
        </div>

        <div className='entcapcard'>
        <img src={picthree} alt='admipicthree'/><br/>
          <h2> Continuous Innovation </h2>
          <ReadMore className='text'
          text={'We stay abreast of the latest security trends, technologies, and best practices to ensure that our clients benefit from the most effective security solutions available. We offer end-to-end management and support for leading security platforms, ensuring comprehensive protection for your network.'}
          maxLines={3}/>
        </div>

        <div className='entcapcard'>
        <img src={picfive} alt='admipicfour'/><br/>
          <h2> Reliablity and Security </h2>
          <ReadMore className='text'
          text={'We prioritize the reliability and security of your network infrastructure, implementing robust security measures and industry best practices to mitigate risks and ensure compliance. With 24/7 monitoring and proactive threat detection, we detect and respond to security incidents in real-time, minimizing the impact on your business.'}
          maxLines={3}/>
        </div>

        <div className='entcapcard'>
        <img src={picfour} alt='admipicfive'/><br/>
          <h2> Partnership and Collaboration</h2>
          <ReadMore className='text'
          text={'We view our relationship with clients as a collaborative partnership, working closely with you to understand your unique security needs and deliver solutions that align with your business objectives. We prioritize customer satisfaction and strive to deliver exceptional service, support, and results to our clients.'}
          maxLines={3}/>
        </div>

        {/* <div className='entcapcard'>
        <img src={picsix} alt='admipicsix'/><br/>
          <h2> Partnership and Collaboration</h2>
          <ReadMore className='text'
          text={'We view our relationship with clients as a collaborative partnership, working closely with you to understand your unique security needs and deliver solutions that align with your business objectives. We prioritize customer satisfaction and strive to deliver exceptional service, support, and results to our clients.'}
          maxLines={4}/>
        </div> */}
      </div>
    </div>
  )
}

export default Infracapabilities