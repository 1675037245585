import React, {useEffect} from 'react'
import aimicoverpic from '../Enterprise App/Pics/softwarecoverpic.png'
import './Enterprise.css'
import Entabout from './Entabout'
import Entcapabilities from './Entcapabilities'
import Clients from '../../../../Clients/Clients'
import Entwhatwedo from './Entwhatwedo'
import Adddigitrans from '../../Adddigitrans/Adddigitrans'
// import Svrtestimonials from '../../../../Svrtestimonials/Svrtestimonials'
import Footer from '../../../../Footer/Footer'
import Herotestimonials from '../../../../Herotestimonials/Herotestimonials'
import Partners from '../../../../../Components/Partners/Partners'

function Enterprise() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);
  return ( 
    <div>
      <div className='ent-header'>
      <img src={aimicoverpic} alt='entcovrepic'/>

      <h1>Enterprise Application Development</h1>
      <p>
      Enterprise application development involves several phases,
      including requirements gathering, design, development,
      testing, deployment, and maintenance.
      </p> 
      </div>   
      <Entabout/>
      <br/><br/>
      <Herotestimonials/>
      <br/><br/><br/>
      <Entcapabilities/>
      <Clients/>
      <Entwhatwedo/>
      <Adddigitrans/>
      <Partners/>
      {/* <Svrtestimonials/> */}
      <br/> <br/>
      <Footer/>
    </div>
  )
}

export default Enterprise

