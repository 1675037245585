import React from 'react'
import './Ourvision.css'
// import { vector } from '../../Assets/Images'
import vision from '../Aboutus/Aboutus pics/vision.png'
import mission from '../Aboutus/Aboutus pics/mission.png'

const Ourvision = () => {
  return (
    <div className='whatsuscontainer'>

      <div className='whatusinnerborder'>
        {/* <img className='vectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='whatusforyour'>
          <h1> What We Do </h1>  
        </div>
      </div>

      <div className='whatuscardcontainer'>
        <div className='whatuscard'>
          <img src={mission} alt='mission' className='imgmission'/>
          <h2> Our Mission </h2>
          <p>
          To make technology an asset for your business not a problem
          </p>
        </div>

        <div className='whatuscard'>
        <img src={vision} alt='mission' className='imgmission'/>
          <h2> Our Vision </h2>
          <p>
          To be the partner of choice for the world’s leading IT & Software service providers.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Ourvision