import React, {useEffect} from 'react'
import cscloud from '../Cloudservice/Cspics/cscloud.png'
import './Cloudservice.css'
import Csabout from './Csabout'
import Cscapabilities from './Cscapabilities'
import Clients from '../../../../Clients/Clients'
import Cswhatwedo from './Cswhatwedo'
// import Cstestimonials from '../../../../Svrtestimonials/Svrtestimonials'
import Adddigitrans from '../../Adddigitrans/Adddigitrans'
import Footer from '../../../../Footer/Footer'
import Herotestimonials from '../../../../Herotestimonials/Herotestimonials'
import Partners from '../../../../../Components/Partners/Partners'


function Managedcloud() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);
  return ( 
    <div>
      <div className='csheader'>
      <img className='mcheaderimg' src={cscloud} alt='cscloud'/>

      <h1> CLOUD SERVICES – DevOps, DevSecOps, SRE </h1>
      <p>
      Leveraging cloud services is essential for businesses seeking to innovate, scale, and remain competitive. Explore how our cloud services can drive your business forward.
      </p> 
      </div>
      <Csabout/>   
      <br/><br/>
      <Herotestimonials/>
      <br/><br/><br/>
      <Cscapabilities/> 
      <Clients/>
      <Cswhatwedo/>
      <Adddigitrans/>
      <Partners/>
      {/* <Cstestimonials/> */}
      <br/><br/>
      <Footer/>
    </div>
  )
}

export default Managedcloud

