import React from 'react'
import aboutam from './Pics/aboutpic.png'
import './Infraabout.css'

function Entabout() {
  return (
    <div className='entcontainer'>
    <div className='aboutent'>
        <h1>
        YOUR MANAGED NETWORK SECURITY STARTS HERE
        </h1><br/>
        <p>
        At Technacle, we offer comprehensive Managed Network Security Services designed to protect your organization's valuable assets, data, and operations. Our expertise in leading security platforms, we provide tailored solutions to meet your unique security needs with our proactive approach, advanced technologies, and ensure that your network remains secure and resilient against even the most sophisticated attacks.
        </p>
        <p>
        Protecting your network from evolving cyber threats requires more than just off-the-shelf solutions. Thus, we provide cutting-edge Managed Network Security Services that ensure the protection, integrity, and resilience of your network infrastructure.
        </p>
    </div>
        <div className='aboutentpic'>
            <img src={aboutam} alt='aboutam'/>
        </div>
    </div>
  )
}

export default Entabout