import React from 'react'
import aboutam from './Pics/aboutpic.png'
import './Infraabout.css'

function Entabout() {
  return (
    <div className='entcontainer'>
    <div className='aboutent'>
        <h1>
        Your IT Infrastructure Support and Maintenance starts here
        </h1><br/>
        <p>
        IT infrastructure and maintenance refer to the processes, practices, and technologies used to ensure the efficient and reliable operation of information technology systems and applications. This includes servers, networks, hardware, software, and databases.
        When it comes to web content, IT infrastructure and maintenance play a critical role in ensuring that the content is available, accessible, and secure. This involves regularly monitoring and maintaining servers, networks, and other components of the infrastructure to prevent downtime, data loss, and other issues.
        </p>
        <p>
        In addition to infrastructure maintenance, it's also important to regularly update and maintain web content. This includes reviewing and updating website content, fixing broken links, optimizing web pages for search engines, and ensuring that the website is user-friendly and accessible to all users.
        </p>
    </div>
        <div className='aboutentpic'>
            <img src={aboutam} alt='aboutam'/>
        </div>
    </div>
  )
}

export default Entabout