import React from 'react'
import './Mccapabilities.css'
// import { vector } from '../../../../../Assets/Images'
import {picone, pictwo, picthree, picfour, picfive} from '../../Servicespics/Colorpics'

const Topicsdigi = () => {
  return (
    <div className='container'>

      <div className='innerborder'>
        {/* <img className='vectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='foryour1'>
        <br /><br /><br /> <h1> Capabilities</h1><br /><br /><br />
        </div>
      </div>

      <div className='cardcontainer'>
        <div className='card'>
        <img src={picone} alt='mccapone'/><br/>
          <h2> Experience and Expertise</h2>
          <p>
          With years of experience in cloud technology and a team of certified professionals, we have the knowledge and skills to deliver exceptional results for your business.
          </p>
        </div>

        <div className='card'>
          <img src={pictwo} alt='mccaptwo'/><br/>
          <h2> Commitment to Excellence </h2>
          <p>
          We are committed to providing the highest level of service and support to our clients. Your success is our success, and we go above and beyond to ensure that you achieve your goals in the cloud.
          </p>
        </div>

        <div className='card'>
          <img src={picthree} alt='mccapthree'/><br/>
          <h2> Continuous Innovation </h2>
          <p>
          We stay ahead of the curve by staying abreast of the latest trends, technologies, and best practices in cloud computing. We leverage innovation to drive continuous improvement and deliver cutting-edge solutions to our clients.
          </p>
        </div>

        <div className='card'>
          <img src={picfour} alt='mccapfour'/><br/>
          <h2> Customer-Centric Approach </h2>
          <p>
          Your satisfaction is our top priority. We listen to your needs, understand your challenges, and work collaboratively with you to develop customized solutions that meet your unique requirements.
          </p>
        </div>

        <div className='card'>
        <img src={picfive} alt='mccapfive'/><br/>
          <h2> Transparent Communication </h2>
          <p>
          We believe in transparent communication and honest, upfront pricing. You can trust us to provide clear, accurate information and keep you informed every step of the way.
          </p>
        </div>

        {/* <div className='card'>
          <img src={picsix} alt='mccapsix'/><br/>
          <h2> Cost Management</h2>
          <p>
          Achieve cost predictability with our pay-as-you-go pricing model. No need to invest in upfront hardware costs—pay only for the resources you use.
          </p>
        </div> */}
      </div>
    </div>
  )
}

export default Topicsdigi