import React from 'react'
import './Cscapabilities.css'
// import { vector } from '../../../../../Assets/Images'
import ReadMore from '../../../../ReadMore'
import {picone, pictwo, picthree, picfour, picsix} from '../../Servicespics/Colorpics'

const Cscapabilities = () => {
  return (
    <div className='container'>

      <div className='innerborder'>
        {/* <img className='vectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='foryour1'>
        <br /><br /><br /><h1> Capabilities</h1><br /><br /><br />
        </div>
      </div>

      <div className='cardcontainer'>
        <div className='card'>
        <img src={picone} alt='mccapone'/><br/>
          <h2>  Expertise and Experience</h2>
          <ReadMore className='RMore'
            text = {'We bring a wealth of experience and proficiency across various cloud platforms, including AWS, Azure, Google Cloud Platform (GCP), and others. Our team comprises certified professionals with extensive experience in cloud technologies, DevOps, DevSecOps, and SRE. Our teams in-depth knowledge enables us to provide tailored solutions that leverage the unique capabilities of each cloud environment.'}
            maxLines={4}/>
            </div>

        <div className='card'>
        <img src={pictwo} alt='mccaptwo'/><br/>
          <h2> Customized Solutions  </h2>
          <ReadMore className='RMore'
            text = {'We tailor our cloud services to meet your specific business requirements, objectives, and budget constraints. We understand that every organization is different, with its own set of challenges, goals, and constraints. Thats why we take a personalized approach, working closely with you to understand your specific requirements and design custom-tailored solutions that address your unique needs.'}
            maxLines={4}/>
        </div>

        <div className='card'>
        <img src={picthree} alt='mccapthree'/><br/>
          <h2> Proactive Support </h2>
          <ReadMore className='RMore'
            text ={'We provide proactive monitoring, maintenance, and support to keep your cloud infrastructure secure, reliable, and scalable. Effective collaboration and communication are at the heart of our approach. We believe in fostering open dialogue, transparency, and teamwork to ensure alignment with your business objectives, keep you informed every step of the way, and deliver solutions that exceed your expectations.'}
          maxLines={4}/>
        </div>

        <div className='card'>
        <img src={picfour} alt='mccapfour'/><br/>
          <h2> Continuous  Innovation</h2>
          <ReadMore className='RMore'
            text = {'The cloud landscape is constantly evolving, with new technologies and best practices emerging regularly. We stay at the forefront of these developments, continuously learning, experimenting, and innovating to ensure that our clients benefit from the latest advancements in cloud computing. We stay abreast of the latest trends and technologies in cloud computing, ensuring that our clients benefit from the most advanced solutions available.'}
            maxLines={4}/>
        </div>

        {/* <div className='card'>
        <img src={picfive} alt='mccapfive'/><br/>
          <h2> Customer Satisfaction </h2>
          <p>
          We prioritize customer satisfaction and strive to exceed your expectations with our personalized service, transparent communication, and commitment to excellence.
          </p>
        </div> */}

        <div className='card'>
        <img src={picsix} alt='mccapsix'/><br/>
          <h2> Customer Satisfaction</h2>
          <p>
          We prioritize customer satisfaction and strive to exceed your expectations with our personalized service, transparent communication, and commitment to excellence.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Cscapabilities