import React from 'react'
import './Saaswhatwedo.css'
// import { vector } from '../../../../../Assets/Images'
import {saascapone, saascaptwo,saascapthree, saascapfour, saascapfive, saascapsix} from './Saaspics/Saaspics'

const Whatwedosaas = () => {
  return (
    <div className='whatsaascontainer'>

      <div className='whatsaasinnerborder'>
        {/* <img className='whatsaasvectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='whatsaasforyour1'>
        <br /><br /><br /><h1> What We Do </h1>  <br /><br /><br />
        </div>
      </div>

      <div className='whatsaascardcontainer'>
        <div className='whatsaascard'>
        <img src={saascapone} alt='saascapone'></img><br/><br/>
          <h2> Tailored Solutions </h2>
          <p>
          We understand that every business is unique. Our experts collaborate with you to design SaaS solutions that align with your operational goals, ensuring you get the most out of our offerings.
          </p>
        </div>

        <div className='whatsaascard'>
        <img src={saascaptwo} alt='saascaptwo'></img><br/><br/>
          <h2> Onboarding and Training </h2>
          <p>
          Transitioning to new software can be a breeze with our onboarding and training programs. We ensure your teams are equipped with the skills to make the most of our SaaS applications.
          </p>
        </div>

        <div className='whatsaascard'>
        <img src={saascapthree} alt='saascapthree'></img><br/><br/>
          <h2> Optimization Performance </h2>
          <p>
          Our commitment doesn't end at deployment. We continuously monitor and optimize your software performance, delivering an exceptional user experience.
          </p>
        </div>

        <div className='whatsaascard'>
        <img src={saascapfour} alt='saascapfour'></img><br/><br/>
          <h2> 24/7 Support </h2>
          <p>
          Technical issues can arise at any time. Our dedicated support team is available round the clock to address your concerns and provide prompt solutions.
          </p>
        </div>

        <div className='whatsaascard'>
        <img src={saascapfive} alt='saascapfive'></img><br/><br/>
          <h2> Innovation Enablement </h2>
          <p>
          Our SaaS solutions are designed to fuel innovation. With hassle-free updates and access to the latest features, you can stay ahead of the curve in a rapidly evolving business landscape.
          </p>
        </div>

        <div className='whatsaascard'>
        <img src={saascapsix} alt='saascapsix'></img><br/><br/>
          <h2> Strategic Collaboration </h2>
          <p>
          We're not just service providers; we're your strategic partners. Count on us to provide insights, recommendations, and best practices to maximize the impact of our SaaS applications.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Whatwedosaas