import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import slide1 from './slider/ai.png'
// import slide2 from './slider/application.png'
import slide3 from './slider/cloud.png'
import slide4 from './slider/data.png'
import slide5 from './slider/digital.png'
import slide6 from './slider/secure.png'
import './Slides.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export default function Slides() {
  return (
    <>
        <Swiper
        slidesPerView={1}
        spaceBetween={0}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
        <img src={slide3} alt='3'/>
        <div className='content'>
        <h1>CLOUD MANAGEMENT SERVICES</h1>
        <p>
        Envision with Technacle and plan ahead to deploy innovative business solutions for sustainable growth and success.
        </p>
        </div>
        </SwiperSlide>
        {/* <SwiperSlide>
        <img src={slide2} alt='1'/>
        <div className='content'>
        <h1>Best Digital Transformation Solutions</h1>
        <p>
        Optimize and revolutionize your workflow with tailored IT solutions and expert consultation.
        </p>
        </div>
        </SwiperSlide> */}
        <SwiperSlide>
        <img src={slide1} alt='1'/>
        <div className='content'>
        <h1>ARTIFICIAL INTELLIGENCE</h1>
        <p>
        AI also has the potential to improve healthcare, by analyzing medical data to identify patterns and predict outcomes, and assisting with diagnosis and treatment planning.
        </p>
        </div>
        </SwiperSlide>
        
        <SwiperSlide>
        <img src={slide5} alt='5'/>
        <div className='content'>
        <h1>DIGITAL TRANSFORMATION</h1>
        <p>
        Digital transformation is driven by several factors, including the increasing availability of digital technologies, the rise of new business models, and changing customer expectations.
        </p>
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <img src={slide4} alt='4'/>
        <div className='content'>
        <h1>APPLICATION DEVELOPMENT</h1>
        <p>
        With cyber threats evolving at an unprecedented pace, protecting your organizations sensitive data, systems, and assets is more crucial than ever. Our cybersecurity services offer comprehensive solutions to help safeguard your business from cyber attacks and security breaches.
        </p>
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <img src={slide6} alt='6'/>
        <div className='content'>
        <h1>CYBER SECURITY</h1>
        <p>
        With cyber threats evolving at an unprecedented pace, protecting your organizations sensitive data, systems, and assets is more crucial than ever. Our cybersecurity services offer comprehensive solutions to help safeguard your business from cyber attacks and security breaches.
        </p>
        </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
