import React, {useEffect} from 'react'
import aimicoverpic from '../ITmaintenance/Pics/infrastructure-support-and-maintenance.png'
import './Itmaintenance.css'
import Infraabout from './Infraabout'
import Infracapabilities from './Infracapabilities'
import Clients from '../../../../Clients/Clients'
import Infrawhatwedo from './Infrawhatwedo'
import Adddigitrans from '../../Adddigitrans/Adddigitrans'
// import Testimonials from '../../../../Svrtestimonials/Svrtestimonials'
import Footer from '../../../../Footer/Footer' 
import Herotestimonials from '../../../../Herotestimonials/Herotestimonials'
import Partners from '../../../../../Components/Partners/Partners'

function Itmaintenance() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);
  return ( 
    <div>
      <div className='entheader'>
      <img src={aimicoverpic} alt='entcovrepic'/>

      <h1>IT Infrastructure Support And Maintenance</h1>
      <p>
      IT infrastructure and maintenance are essential
      for ensuring the smooth operation and ongoing success
      of any website or online business.
      </p> 
      </div>
      <br/>
      <Infraabout/>
      <br/><br/>
      <Herotestimonials/>
      <br/><br/><br/>
      <Infracapabilities/>
      <Clients/>
      <Infrawhatwedo/>
      <Adddigitrans/>
      <Partners/>
      {/* <Testimonials/> */}
      <br/><br/>
      <Footer/>   
    </div>
  )
}

export default Itmaintenance

