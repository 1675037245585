import React from 'react'
import './Infrawhatwedo.css'
// import { vector } from '../../../../../Assets/Images'
import ReadMore from '../../../../ReadMore'
import {admipicone, admipictwo,admipicthree, admipicfour, admipicfive, admipicsix} from './Pics/Jpics'

const Infrawhatwedo = () => {
  return (
    <div className='whatcontainer'>

      <div className='whatinnerborder'>
        {/* <img className='whatvectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='whatforyour1'>
        <br /><br /><br /><h1> Real-World Applications </h1>  <br /><br /><br />
        </div>
      </div>

      <div className='forescoutwhatcardcontainer'>
        <div className='whatcard'>
        <img src={admipicone} alt='admipicone'></img><br/><br/>
          <h2> Healthcare </h2>
          <ReadMore className='txt'
          text={'Healthcare organizations rely on secure and compliant systems to protect sensitive patient data and ensure uninterrupted delivery of care. The partnership between Technacle and Forescout enables healthcare providers to strengthen their cybersecurity posture, safeguarding against data breaches and ransomware attacks'}
          maxLines={4}/>
        </div>

        <div className='whatcard'>
        <img src={admipictwo} alt='admipictwo'></img><br/><br/>
          <h2> Finance  </h2>
          <ReadMore className='txt'
          text={'Financial institutions face persistent threats from cybercriminals seeking to exploit vulnerabilities in their networks and systems. By leveraging the combined expertise of Technacle and Forescout, banks and financial services firms can fortify their defenses, prevent unauthorized access, and safeguard customer assets.'}
          maxLines={4}/>
        </div>

        <div className='whatcard'>
        <img src={admipicthree} alt='admipicthree'></img><br/><br/>
          <h2> Database Management  </h2>
          <p className='txt1'>
          Databases store and manage critical business data, and IT infrastructure and maintenance teams are responsible for maintaining and optimizing them to ensure optimal performance.
          </p>
        </div>

        <div className='whatcard'>
        <img src={admipicfour} alt='admipicfour'></img><br/><br/>
          <h2> Critical Infrastructure  </h2>
          <ReadMore className='txt'
          text={'Infrastructure sectors, such as energy, transportation, and utilities, are prime targets for cyber-attacks due to their interconnected systems and critical importance to society. The partnership between Technacle and Forescout offers tailored cybersecurity solutions to protect vital infrastructure assets from cyber threats and operational disruptions.'}
          maxLines={4}/>
        </div>

        <div className='whatcard'>
        <img src={admipicfive} alt='admipicfive'></img><br/><br/>
          <h2> Security Measures  </h2>
          <p className='txt1'>
          IT infrastructure and maintenance teams implement security measures like firewalls, intrusion detection systems, and encryption to protect against cyber attacks and data breaches.
          </p>
        </div>

        <div className='whatcard'>
        <img src={admipicsix} alt='admipicsix'></img><br/><br/>
          <h2> Partnership between Technacle and Forescout   </h2>
          <ReadMore className='txt'
          text={'The partnership between Technacle and Forescout represents a significant step forward in the quest for comprehensive cybersecurity solutions that address the evolving threat landscape.By combining their expertise, resources, and innovative technologies, Technacle and Forescout are poised to redefine the standards of cybersecurity excellence. Through enhanced visibility, automated threat response, and scalable solutions, clients can mitigate risks, ensure compliance, and safeguard their most valuable assets in an increasingly interconnected world.'}
          maxLines={3}/>
        </div>
      </div>
    </div>
  )
}

export default Infrawhatwedo