import React from 'react'
import Aboutstaffpic from './Staffingpics/staffing2.png'
import './Aboutstaffing.css'

function Aboutstaffing() {
  return (
    <div className='about-staffcontainer'>
    <div className='about-staff'>
        <h1>
        Technacle Staffing Services
        </h1><br/>
        <p>
        Technacle Staffing Services is a trusted provider of staffing solutions, offering a wide range of services to businesses across industries. With years of experience and a dedicated team of recruitment experts, we have built a reputation for excellence in matching qualified candidates with the right opportunities.
        </p>
        <p>
        We understand that finding the right talent is essential for the success of your business. That's why we offer comprehensive staffing solutions tailored to meet your unique needs and objectives. Whether you're looking to fill temporary positions, hire contractors, or expand your team with full-time employees, we're here to help you find the perfect fit.
        </p>
    </div>
        <div className='about-staffingpic'>
            <img src={Aboutstaffpic} alt='About-staffpic'/>
        </div>
    </div>
  )
}

export default Aboutstaffing