import React from 'react'
import aboutam from './Pics/aboutpic.png'
import './Entabout.css'

function Entabout() {
  return (
    <div className='ent-container'>
    <div className='about-ent'>
        <h1>
        Your Web/Mobile Application Development starts here
        </h1><br/>
        <p>
        To help businesses leverage the power of technology and reach their target audience effectively, we follow a customer-centric approach to web and mobile application development, focusing on understanding our clients' unique needs, challenges, and goals. Our team of experienced developers, designers, and project managers work closely with clients to deliver tailored solutions that align with their vision and objectives. We combine cutting-edge technologies, industry best practices, and creative innovation to develop high-performance, scalable, and user-friendly web and mobile applications.
        </p>
        <p>
        At Technacle, we are dedicated to helping businesses thrive in the digital landscape through our comprehensive Web and Mobile Application Development Services. Whether you need a custom web application, a mobile app, or an e-commerce solution, we have the expertise and capabilities to bring your vision to life.
        </p>
    </div>
        <div className='about-entpic'>
            <img src={aboutam} alt='aboutam'/>
        </div>
    </div>
  )
}

export default Entabout