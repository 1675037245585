import React from 'react'
import './Entwhatwedo.css'
// import { vector } from '../../../../../Assets/Images'
import {bwpicone, bwpictwo,bwpicthree, bwpicfour, bwpicfive, bwpicsix} from '../../Servicespics/Bwpics'

const Entwhatwedo = () => {
  return (
    <div className='what-container'>

      <div className='whatinnerborder'>
        {/* <img className='whatvectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='whatforyour1'>
        <br /><br /><br /> <h1> What We Do </h1>  <br /><br /><br />
        </div>
      </div>

      <div className='entwhat-cardcontainer'>
        <div className='what-card'>
        <img src={bwpicone} alt='admipicone'></img><br/><br/>
          <h2> Strategic Planning </h2>
          <p>
          We understand the complexities of your enterprise. Our experts collaborate closely to design an application strategy that aligns with your business objectives and challenges.
          </p>
        </div>

        <div className='what-card'>
        <img src={bwpictwo} alt='admipictwo'></img><br/><br/>
          <h2> Custom-Tailored Solutions </h2>
          <p>
          There's no one-size-fits-all approach. Our applications are meticulously crafted to address the specific needs and complexities of your enterprise.
          </p>
        </div>

        <div className='what-card'>
        <img src={bwpicthree} alt='admipicthree'></img><br/><br/>
          <h2> Agile Development </h2>
          <p>
          We embrace agile methodologies to ensure transparency, collaboration, and rapid development cycles. Your feedback guides the process every step of the way.
          </p>
        </div>

        <div className='what-card'>
        <img src={bwpicfour} alt='admipicfour'></img><br/><br/>
          <h2> Thorough Testing </h2>
          <p>
          Quality is non-negotiable. We subject our applications to rigorous testing to identify and rectify issues, guaranteeing a seamless and reliable user experience.
          </p>
        </div>

        <div className='what-card'>
        <img src={bwpicfive} alt='admipicfive'></img><br/><br/>
          <h2> Deployment and Support </h2>
          <p>
          Our commitment extends beyond deployment. We provide ongoing support to ensure your enterprise applications remain secure, up-to-date, and optimized.
          </p>
        </div>

        <div className='what-card'>
        <img src={bwpicsix} alt='admipicsix'></img><br/><br/>
          <h2> Strategic Partnership </h2>
          <p>
          We're not just developers; we're your strategic partners in innovation. Count on us to provide insights, recommendations, and a roadmap to navigate your enterprise's digital transformation.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Entwhatwedo