import React from 'react'
import './Infrawhatwedo.css'
// import { vector } from '../../../../../Assets/Images'
import {admipicone, admipictwo,admipicthree, admipicfour, admipicfive, admipicsix} from './Pics/Jpics'
import ReadMore from '../../../../ReadMore'

const Infrawhatwedo = () => {
  return (
    <div className='whatcontainer'>

      <div className='whatinnerborder'>
        {/* <img className='whatvectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='whatforyour1'>
        <br /><br /><br /> <h1> What we Do </h1>  <br /><br /><br />
        </div>
      </div>

      <div className='itsupportwhatcardcontainer'>
        <div className='whatcard'>
        <img src={admipicone} alt='admipicone'></img><br/><br/>
          <h2> Hardware Maintenance </h2>
          <ReadMore className='pera'
          text={'IT infrastructure and maintenance teams are responsible for maintaining and repairing hardware, such as servers, storage devices, and networking equipment.'}
          maxLines={3}/>
        </div>

        <div className='whatcard'>
        <img src={admipictwo} alt='admipictwo'></img><br/><br/>
          <h2> Software Updates  </h2>
          <ReadMore className='pera'
          text={'Keeping software up-to-date is critical for ensuring system security and performance. IT infrastructure and maintenance teams regularly update operating systems, applications, and security software to protect against vulnerabilities and bugs.'}
          maxLines={3}/>
        </div>

        <div className='whatcard'>
        <img src={admipicthree} alt='admipicthree'></img><br/><br/>
          <h2> Network Monitoring  </h2>
          <ReadMore className='pera'
          text={'IT infrastructure and maintenance teams monitor networks to ensure that they are operating efficiently and that issues are quickly identified and addressed.'}
          maxLines={3}/>
        </div>

        <div className='whatcard'>
        <img src={admipicfour} alt='admipicfour'></img><br/><br/>
          <h2> Backup and Recovery  </h2>
          <ReadMore className='pera'
          text={'Regular backups and recovery plans help ensure that data is protected against loss or corruption. IT infrastructure and maintenance teams are responsible for developing and executing backup and recovery plans.'}
          maxLines={3}/>
        </div>

        <div className='whatcard'>
        <img src={admipicfive} alt='admipicfive'></img><br/><br/>
          <h2> Security Measures  </h2>
          <ReadMore className='pera'
          text={'IT infrastructure and maintenance teams implement security measures like firewalls, intrusion detection systems, and encryption to protect against cyber attacks and data breaches.'}
          maxLines={3}/>
        </div>

        <div className='whatcard'>
        <img src={admipicsix} alt='admipicsix'></img><br/><br/>
          <h2> Database Management   </h2>
          <ReadMore className='pera'
          text={'Databases store and manage critical business data, and IT infrastructure and maintenance teams are responsible for maintaining and optimizing them to ensure optimal performance.'}
          maxLines={3}/>
        </div>
      </div>
    </div>
  )
}

export default Infrawhatwedo