import React from 'react'
import './Cmwhatwedo.css'
// import { vector } from '../../../../../Assets/Images'
// import ReadMore from '../../../../ReadMore'
import {mccapone, mccaptwo,mccapthree, mccapfour, mccapfive} from './Cmpics/Mcpics'

const Cmwhatwedo = () => {
  return (
    <div className='whatcscontainer'>

      <div className='whatcsinnerborder'>
        {/* <img className='whatcsvectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='whatcsforyour1'>
        <br /><br /><br /> <h1> What We Do </h1>  <br /><br /><br />
        </div>
      </div>
      <div className='whatcs-cardcontainer'>
        <div className='whatcscard'>
        <img src={mccapone} alt='mccapone'></img><br/><br/>
          <h2> Custom Solutions </h2>
          <p>
          We recognize that every business is unique. Our solutions are tailor-made to fit your specific requirements, ensuring that you get the most out of your cloud investment.
          </p>
        </div>

        <div className='whatcscard'>
        <img src={mccaptwo} alt='mccaptwo'></img><br/><br/>
          <h2> Round-the-Clock Support </h2>
          <p>
          Cloud issues can arise at any time. Our dedicated support team is available 24/7 to address your concerns and provide timely solutions.
          </p>
        </div>

        <div className='whatcscard'>
        <img src={mccapthree} alt='mccapthree'></img><br/><br/>
          <h2> Continuous Improvement </h2>
          <p>
          The cloud landscape is ever-evolving. We stay up-to-date with the latest trends and technologies to continuously optimize your cloud environment and keep you ahead of the curve.
          </p>
        </div>

        <div className='whatcscard'>
        <img src={mccapfour} alt='mccapfour'></img><br/><br/>
          <h2> Strategic Guidance </h2>
          <p>
          Our experts don't just manage your cloud—they guide you in making informed decisions that align with your long-term business vision.
          </p>
        </div>

        <div className='whatcscard'>
        <img src={mccapfive} alt='mccapfive'></img><br/><br/>
          <h2> Business Agility </h2>
          <p>
          By offloading the complexities of cloud management to us, you can focus on driving innovation and responding quickly to market changes.
          </p>
        </div>

        {/* <div className='whatcscard'>
        <img src={mccapsix} alt='mccapsix'></img><br/><br/>
          <h2> DevSecOps: Integrating Security into Every Step </h2>
          <ReadMore className='RMore'
          text={'Security is paramount in todays threat landscape, which is why we offer DevSecOps services to integrate security into every stage of your software development lifecycle. From threat modeling and vulnerability scanning to secure coding practices and automated security testing, we embed security into your development and deployment pipelines. By adopting a DevSecOps approach, you can identify and mitigate security risks early, achieve compliance with regulatory requirements, and build trust with your customers.'}
          maxLines={4}/>
        </div> */}
        </div>
    </div>
  )
}

export default Cmwhatwedo