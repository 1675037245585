import React from 'react'
import './Topicsdigi.css'
// import { vector } from '../../../../Assets/Images'
import {picone, pictwo, picthree, picfour, picfive, picsix} from '../Servicespics/Colorpics'

const Topicsdigi = () => {
  return (
    <div className='topiccontainer'>

      <div className='innerborder1'>
        {/* <img className='vectorimg' src={vector} alt='vector' />
        <text className='discover'>Discover Our Company</text> */}
        <br /><br /><br />
        <div className='foryour1'>
          <h1> Capabilities</h1> <br /><br /><br />
        </div>
      </div>

      <div className='topicardcontainer'>
        <div className='card'>
        <img src={picone} alt='digital'/><br/>
          <h2>Digital Strategy and Consulting</h2>
          <p className='txt'>
          Crafting a successful Digital Transformation begins with a robust strategy. Our seasoned consultants collaborate closely with your team to create a tailored roadmap that aligns technology initiatives with your business goals.
          </p>
        </div>

        <div className='card'>
        <img src={pictwo} alt='artificial'/><br/>
          <h2>Process Optimization</h2>
          <p className='txt'>
          We identify inefficiencies in your current processes and reimagine them for the digital age. Through automation, integration, and reengineering, we optimize workflows for maximum efficiency.
          </p>
        </div>

        <div className='card'>
        <img src={picthree} alt='cloud'/><br/>
          <h2>customer experience Enhancement</h2>
          <p className='txt'>
          Elevate customer satisfaction through enhanced digital interactions. Our solutions help you create seamless and personalized experiences that foster customer loyalty and drive growth.
          </p>
        </div>

        <div className='card'>
        <img src={picfour} alt='application'/><br/>
          <h2>Data-Driven Insights</h2>
          <p className='txt'>
          Leverage the power of data to make informed decisions. Our analytics solutions turn raw data into actionable insights, enabling you to anticipate trends, identify opportunities, and mitigate risks.
          </p>
        </div>

        <div className='card'>
        <img src={picfive} alt='itinfra'/><br/>
          <h2>Technology Implementation</h2>
          <p className='txt'>
          We provide end-to-end technology implementation, from selecting the right tools to seamless integration. Our expertise spans across cloud solutions, AI, IoT, and more.
          </p>
        </div>

        <div className='card'>
        <img src={picsix} alt='software'/><br/>
          <h2>Change Management</h2>
          <p className='txt'>
          A successful transformation is about people as much as technology. Our change management strategies empower your workforce to embrace the changes, ensuring a smooth transition.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Topicsdigi