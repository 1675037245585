import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Clients.css'
import { aadani, cloud, nations, techbridge, vasplanet} from '../../Assets/clients/Clintimages'

function Clients() {

    const settings = {
        dots: false,
        infinite: true, 
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 500,
        cssEase: "linear"
      };

  return (
    <div>
        
       
    <div className='clintimagecontainer'>
    <div className='ourclientsheadding'>
            <h1> OUR CLIENTS </h1>
        </div>
        <Slider {...settings}>
        <div className='clintimage'>
            <img src={aadani} alt='aadani'/>
        </div>
        <div className='clintimage'>
            <img src={cloud} alt='cloud'/>
        </div>
        <div className='clintimage'> 
            <img src={nations} alt='nations'/>
        </div>
        <div className='clintimage'>
            <img src={techbridge} alt='techbridge'/>
        </div>
        <div className='clintimage'>
            <img src={vasplanet} alt='vasplanet'/>
        </div>
        </Slider>
    </div>
    </div>
  )
}

export default Clients