import React from 'react'
import Aboutuspic from './illustration-2.png'
import './Abouttech.css'

function Abouttech() {
  return (
    <div className='aboutuscontainer'>
    <div className='aboutus'>
        <h1>
        ABOUT TECHNACLE
        </h1><br/>
        <h2>
        We run all kinds of IT services that vow your success
        </h2><br/>
        <p>
        We were established in 2019 by a global team of professionals with decades of experience in delivering operational excellence to clients. Since then, we are growing consistently and evolved and expanded our capabilities to provide best-in-class services to our clients with our hybrid service model. Our on-shore consultants and engineers work with clients to determine the right solution while achieving great cost advantages with the prudent use of offshore delivery centers.
        </p>
    </div>
        <div className='aboutuspic'>
            <img src={Aboutuspic} alt='Aboutuspic'/>
        </div>
    </div>
  )
}

export default Abouttech