import React from 'react'
import aboutsaas from './Saaspics/aboutsaas.png'
import './Saasabout.css'

function Saasabout() {
  return (
    <div className='saascontainer'>
    <div className='aboutsaas'>
        <h1>
        Your Software as a Service (SaaS) starts here
        </h1><br/>
        <p>
        Software as a Service (SaaS) is a cloud computing model that delivers software applications over the internet. SaaS applications are hosted and managed by a third-party provider, and users can access them through a web browser or mobile app.
        </p>
        <p>
        At Technacle IT Services, we specialize in delivering cutting-edge Software as a Service (SaaS) solutions that empower businesses to leverage powerful software applications without the burden of installation, maintenance, and management. In today's fast-paced digital landscape, having access to the right tools can make all the difference. Our SaaS offerings are designed to streamline your operations and boost efficiency.
        </p>
    </div>
        <div className='aboutsaaspic'>
            <img src={aboutsaas} alt='aboutsaas'/>
        </div>
    </div>
  )
}

export default Saasabout