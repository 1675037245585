import React from 'react'
import aboutam from './Pics/aboutpic.png'
import './Entabout.css'

function Entabout() {
  return (
    <div className='ent-container'>
    <div className='about-ent'>
        <h1>
        Your Enterprise Application Development transformation starts here
        </h1><br/>
        <p>
        Enterprise application development is the process of developing software applications that are used by large organizations to manage their operations. These applications are typically complex and require a deep understanding of the business domain.
        </p>
        <p>
        At Technacle IT Services, we specialize in creating sophisticated and tailored Enterprise Applications that empower businesses to achieve operational excellence, enhance collaboration, and drive growth. In today's complex business environment, having robust and agile applications is essential for managing processes, data, and resources effectively. Our Enterprise Application Development offerings are designed to deliver solutions that scale with your enterprise's needs.
        </p>
    </div>
        <div className='about-entpic'>
            <img src={aboutam} alt='aboutam'/>
        </div>
    </div>
  )
}

export default Entabout