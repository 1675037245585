import React, {useEffect} from 'react'
import aimicoverpic from '../AIMI Solutions/Aimipics/aimicoverpic.png'
import './Aimi.css'
import Aimiabout from './Aimiabout'
import Aimicapabilities from './Aimicapabilities'
import Clients from '../../../../Clients/Clients'
import Aimiwhatwedo from './Aimiwhatwedo'
import Adddigitrans from '../../Adddigitrans/Adddigitrans'
// import Aimitestimonials from '../../../../Svrtestimonials/Svrtestimonials'
import Footer from '../../../../Footer/Footer'
import Herotestimonials from '../../../../Herotestimonials/Herotestimonials'
import Partners from '../../../../../Components/Partners/Partners'

function Aimi() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);
  return ( 
    <div>
      <div className='aimiheader'>
      <img src={aimicoverpic} alt='aimicovrepic'/>

      <h1> AI / ML Solutions </h1>
      <p>
      Machine learning also has the potential to improve healthcare,
      by analyzing medical data to identify patterns and predict outcomes,
      and assisting with diagnosis and treatment planning.
      </p> 
      </div>   
      <br/><br/>
      <Aimiabout/>
      <br/><br/>
      <Herotestimonials/>
      <br/><br/><br/>
      <Aimicapabilities/>
      <Clients/>
      <Aimiwhatwedo/>
      <Adddigitrans/>
      <Partners/>
      {/* <Aimitestimonials/> */}
      <br/> <br/> 
      <Footer/>
    </div>
  )
}

export default Aimi

