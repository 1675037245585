import React from 'react'
import './Topics.css'
import { vector, digitallogo, artificiallogo, cloudlogo, applogo, itlogo, softwarelogo } from '../../Assets/Images'


const   Topics = () => {
  return (
    <div className='topiccontainer1'>

      <div className='tinnerborder'>
        <img className='victorimg' src={vector} alt='vector' />
        <text className='discover'>Discover Our Company</text><br /><br /><br />
        <div className='foryourhome'>
          <h1> For your very specific industry,
            we have highly-tailored IT solutions.</h1>
        </div>
      </div>
      <div className='cardcontainer'>
        <div className='card'>
          <img src={digitallogo} alt='digital' />
          <h2>Digital Transformation</h2>
          <p>
            Digital transformation is the process of using digital technologies to fundamentally change the way organizations operate and deliver value to customers.
          </p>
          {/* <a href='#' style={{color:'darkblue'}}>Read More</a> */}
        </div>

        <div className='card'>
          <img src={artificiallogo} alt='artificial' />
          <h2>Artificial Intelligence</h2>
          <p>
          AI also has the potential to improve healthcare, by analyzing medical data to identify patterns and predict outcomes, and assisting with diagnosis and treatment planning.
          </p>
          {/* <a href='#' style={{color:'darkblue'}}>Read More</a> */}
        </div>

        <div className='card'>
          <img src={cloudlogo} alt='cloud' />
          <h2>Cloud Management</h2>
          <p>
          Cloud management is the process of managing and optimizing cloud-based resources and services, including cloud storage, computing power, and networking capabilities.
          </p>
          {/* <a href='#' style={{color:'darkblue'}}>Read More</a> */}
        </div>

        <div className='card'>
          <img src={applogo} alt='application' />
          <h2>Application Development</h2>
          <p>
          Consequently, there is much demand for Application Development and Maintenance (ADM) Services, which focus on swift development, testing, deployment and management of high quality software.
          </p>
          {/* <a href='#' style={{color:'darkblue'}}>Read More</a> */}
        </div>

        <div className='card'>
          <img src={itlogo} alt='itinfra' />
          <h2>IT Infrastructure support</h2>
          <p>
          IT infrastructure and maintenance refer to the processes, practices, and technologies used to ensure the efficient and reliable operation of information technology systems and applications.
          </p>
          {/* <a href='#' style={{color:'darkblue'}}>Read More</a> */}
        </div>

        <div className='card'>
          <img src={softwarelogo} alt='software' />
          <h2>Software Development</h2>
          <p>
          Technacle IT Services embodies best practices and best-in-class functionality on a single platform; developed using a set of reliable international best practices- CMMI standards.
          </p>
         </div>
      </div>
    </div>
  )
}

export default Topics