import React from 'react'
import './Entcapabilities.css'
// import { vector } from '../../../../../Assets/Images'
import {picone, pictwo, picthree, picfour, picfive, picsix} from '../../Servicespics/Colorpics'

const Entcapabilities = () => {
  return (
    <div className='ent-capcontainer'>

      <div className='ent-capinnerborder'>
        {/* <img className='entvectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='ent-capforyour1'>
        <br /><br /><br /><h1> Capabilities</h1><br /><br /><br />
        </div>
      </div>

      <div className='ent-capcardcontainer'>
        <div className='ent-capcard'>
         <img className='cardimg' src={picone} alt='admipicone'/><br/>
          <h2> Custom Enterprise Solutions </h2>
          <p>
          We create tailor-made applications that align perfectly with your unique business requirements. From design to deployment, our experts ensure that your vision is translated into a seamless and efficient application.
          </p>
        </div>

        <div className='ent-capcard'>
        <img className='cardimg' src={pictwo} alt='admipictwo'/><br/>
          <h2> Collaboration Tools </h2>
          <p>
          Foster efficient teamwork with collaborative applications tailored to your enterprise's workflows. From document sharing to communication platforms, our solutions optimize collaboration.
          </p>
        </div>

        <div className='ent-capcard'>
        <img className='cardimg' src={picthree} alt='admipicthree'/><br/>
          <h2> Workflow Automation </h2>
          <p>
          Streamline your processes by automating repetitive tasks and workflows. Our applications enable you to reduce manual intervention, enhance efficiency, and minimize errors.
          </p>
        </div>

        <div className='ent-capcard'>
        <img className='cardimg' src={picfour} alt='admipicfour'/><br/>
          <h2> Security and Compliance </h2>
          <p>
          Data security is paramount. Our Enterprise Application solutions incorporate robust security measures and compliance standards to protect your sensitive information.
          </p>
        </div>

        <div className='ent-capcard'>
        <img className='cardimg' src={picfive} alt='admipicfive'/><br/>
          <h2> Integration Services </h2>
          <p>
          Seamlessly connect your enterprise applications with each other and third-party systems. Our integration solutions enhance data flow, streamline processes, and provide a comprehensive view of your operations.
          </p>
        </div>

        <div className='ent-capcard'>
        <img className='cardimg' src={picsix} alt='admipicsix'/><br/>
          <h2> Scalability and Performance</h2>
          <p>
          Enterprise applications need to perform flawlessly even under heavy loads. Our development approach prioritizes scalability and performance optimization to ensure consistent functionality.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Entcapabilities