import React from 'react'
import './Infrawhatwedo.css'
// import { vector } from '../../../../../Assets/Images'
import {admipicone, admipictwo,admipicthree, admipicfour, admipicfive, admipicsix} from './Pics/Jpics'
import ReadMore from '../../../../ReadMore'

const Infrawhatwedo = () => {
  return (
    <div className='whatcontainer'>

      <div className='whatinnerborder'>
        {/* <img className='whatvectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='whatforyour1'>
        <br /><br /><br /><h1> What we Do </h1>  <br /><br /><br />
        </div>
      </div>

      <div className='itdesignwhatcardcontainer'>
        <div className='whatcard'>
        <img src={admipicone} alt='admipicone'></img><br/><br/>
          <h2> IT Infrastructure Design </h2>
          <ReadMore className='txt'
          text={'Designing an effective IT infrastructure requires a deep understanding of your business goals, operational requirements, and technological landscape. Our experienced architects work closely with you to assess your current infrastructure, identify pain points, and develop a tailored blueprint for your future IT environment. We focus on scalability, security, and efficiency to ensure that your infrastructure supports your growth and innovation initiatives.'}
          maxLines={3}/>
        </div>

        <div className='whatcard'>
        <img src={admipictwo} alt='admipictwo'></img><br/><br/>
          <h2> IT Infrastructure Implementation  </h2>
          <ReadMore className='txt'
          text={'Once the design phase is complete, our skilled technicians take charge of implementing the proposed infrastructure solutions. We handle everything from procuring hardware and software to configuring networks, servers, storage systems, and security protocols. Our meticulous approach ensures a smooth deployment process, with minimal disruption to your daily operations.'}
          maxLines={3}/>
        </div>

        <div className='whatcard'>
        <img src={admipicthree} alt='admipicthree'></img><br/><br/>
          <h2> Network Setup and Optimization  </h2>
          <ReadMore className='txt'
          text={'A robust network infrastructure is the backbone of modern businesses, facilitating seamless communication, data transfer, and collaboration. Our network specialists design and deploy tailored network solutions optimized for performance, reliability, and security. We leverage the latest technologies and industry best practices to create LAN, WAN, and wireless networks that meet your specific requirements and support your business objectives.'}
          maxLines={3}/>
        </div>

        <div className='whatcard'>
        <img src={admipicfour} alt='admipicfour'></img><br/><br/>
          <h2> Backup and Recovery  </h2>
          <ReadMore className='txt'
          text={'Regular backups and recovery plans help ensure that data is protected against loss or corruption. IT infrastructure and maintenance teams are responsible for developing and executing backup and recovery plans.'}
          maxLines={3}/>
        </div>

        <div className='whatcard'>
        <img src={admipicfive} alt='admipicfive'></img><br/><br/>
          <h2> Cloud integration and Migration  </h2>
          <ReadMore className='txt'
          text={'Embracing cloud technologies offers numerous benefits, including scalability, flexibility, and cost-efficiency. Our cloud specialists assist you in integrating cloud services from leading providers such as AWS, Azure, GCP, and Oracle into your existing infrastructure. Whether you are migrating existing workloads or building new cloud-native applications, we ensure a seamless transition while maximizing the benefits of cloud computing.'}
          maxLines={3}/>
        </div>

        <div className='whatcard'>
        <img src={admipicsix} alt='admipicsix'></img><br/><br/>
          <h2> Backup and Disaster Recovery   </h2>
          <ReadMore className='txt'
          text={'Data loss or system downtime can have severe consequences for your business. Our backup and disaster recovery solutions provide peace of mind by ensuring that your critical data is always protected and accessible. We implement automated backup processes, redundant storage systems, and disaster recovery plans to minimize downtime and restore operations swiftly in the event of a disaster or system failure.'}
          maxLines={3}/>
        </div>

        <div className='whatcard'>
        <img src={admipicone} alt='admipicone'></img><br/><br/>
          <h2> IT Infrastructure Support and maintenance  </h2>
          <ReadMore className='txt'
          text={'Maintaining peak performance and reliability of your IT infrastructure requires ongoing monitoring and maintenance. Our dedicated support team offers 24/7 monitoring, proactive maintenance, troubleshooting, and rapid response to address any issues that may arise. We strive to keep your systems running smoothly, allowing you to focus on your core business activities without worrying about IT infrastructure disruptions.'}
          maxLines={3}/>
        </div>
      </div>
    </div>
  )
}

export default Infrawhatwedo