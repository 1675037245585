import {React, useEffect} from 'react'
import './Certification.css'
import Aos from 'aos'
import 'aos/dist/aos.css'
import {start, startup, startup1, nasscom, iso, iso1, dun} from '../../Assets/Images'

function Certification() {
    useEffect (() =>{
      Aos.init();
      Aos.refresh(); 
    }, []);
  return (
    <div className='certi-container'>
    <div className='certcap'>
      <p>CERTIFICATION</p>
    <div className='certification'>
        <img data-aos="zoom-in-left" src={start} alt='start'></img>
        <img data-aos="zoom-in-left" src={startup} alt='start'></img>
        <img data-aos="zoom-in-left" src={startup1} alt='start'></img>
        <img data-aos="flip-up" src={iso} alt='start'></img>
        <img data-aos="zoom-in-right" src={iso1} alt='start'></img>
        <img data-aos="zoom-in-right" src={nasscom} alt='start'></img>
        <img data-aos="zoom-in-right" src={dun} alt='start'></img>
    </div>
    </div>
    </div>
  )
}

export default Certification