import React from 'react'
import './Infrawhatwedo.css'
// import { vector } from '../../../../../Assets/Images'
import {admipicone, admipictwo,admipicthree, admipicfour, admipicfive, admipicsix} from './Pics/Jpics'
import ReadMore from '../../../../ReadMore'

const Infrawhatwedo = () => {
  return (
    <div className='whatcontainer'>

      <div className='whatinnerborder'>
        {/* <img className='whatvectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='whatforyour1'>
        <br /><br /><br /> <h1> What We Do </h1> <br /><br /><br /> 
        </div>
      </div>

      <div className='secuwhatcardcontainer'>
        <div className='whatcard'>
        <img src={admipicone} alt='admipicone'></img><br/><br/>
          <h2> FortiGate Managed Services </h2>
          <ReadMore className='text'
          text={'Our team of certified Fortinet experts delivers end-to-end management and support for FortiGate firewalls, ensuring robust network security and threat prevention. We handle firewall configuration, policy management, firmware updates, and ongoing monitoring to keep your network protected against cyber threats.'}
          maxLines={3}/>
        </div>

        <div className='whatcard'>
        <img src={admipictwo} alt='admipictwo'></img><br/><br/>
          <h2> pfSense Managed Services </h2>
          <ReadMore className='text'
          text={'Benefit from our pfSense Managed Services, where we manage and maintain your pfSense firewall appliances to enforce security policies, control network traffic, and protect against intrusions. Our experts handle configuration, optimization, and troubleshooting to ensure optimal performance and security for your network.'}
          maxLines={3}/>
        </div>

        <div className='whatcard'>
        <img src={admipicthree} alt='admipicthree'></img><br/><br/>
          <h2> Checkpoint Managed Services </h2>
          <ReadMore className='text'
          text={'With our Check Point Managed Services, we provide comprehensive management and support for Check Point firewall and security solutions. From rule-base configuration to threat prevention updates and log analysis, we ensure that your Check Point environment remains secure and resilient.'}
          maxLines={3}/>
        </div>

        <div className='whatcard'>
        <img src={admipicfour} alt='admipicfour'></img><br/><br/>
          <h2> Palo Alto Networks Managed Services </h2>
          <ReadMore className='text'
          text={'Our Palo Alto Networks Managed Services offer proactive management and support for Palo Alto Next-Generation Firewalls (NGFWs) and security solutions. We handle policy management, threat prevention updates, and advanced security features to protect your network from advanced cyber threats.'}
          maxLines={3}/>
        </div>

        <div className='whatcard'>
        <img src={admipicfive} alt='admipicfive'></img><br/><br/>
          <h2> Juniper Networks Managed Services </h2>
          <ReadMore className='text'
          text={'Rely on our Juniper Networks Managed Services for expert management and support of Juniper firewall and security devices. Our certified Juniper engineers handle configuration, optimization, and troubleshooting to ensure maximum security and performance for your network.'}
          maxLines={3}/>
        </div>

        <div className='whatcard'>
        <img src={admipicsix} alt='admipicsix'></img><br/><br/>
          <h2> Cisco Managed Services </h2>
          <ReadMore className='text'
          text={'Leverage our Cisco Managed Services for comprehensive management and support of Cisco firewall and security solutions. From Cisco ASA firewalls to Cisco Firepower Threat Defense (FTD), we provide proactive monitoring, configuration management, and security updates to protect your network infrastructure.'}
          maxLines={3}/>
        </div>

        <div className='whatcard'>
        <img src={admipicthree} alt='admipicthree'></img><br/><br/>
          <h2> Forescout Managed Services </h2>
          <ReadMore className='text'
          text={'Our Forescout Managed Services offer centralized management and monitoring of Forescout CounterACT for network access control and device visibility. We ensure continuous monitoring, policy enforcement, and threat response to secure your network against unauthorized access and insider threats.'}
          maxLines={3}/>
        </div>
      </div>
    </div>
  )
}

export default Infrawhatwedo