import React from 'react'
import aboutam from './Aimipics/aboutai.png'
import './Aimiabout.css'

function Aimiabout() {
  return (
    <div className='amcontainer'>
    <div className='aboutam'>
        <h1>
        Your Machine Learning transformation starts here
        </h1><br/>
        <p>
        Machine learning is a type of artificial intelligence (AI) that allows software applications to become more accurate in predicting outcomes without being explicitly programmed to do so. Machine learning algorithms use historical data as input to predict new output values.
        </p>
        <p>
        At Technacle IT Services, we specialize in harnessing the incredible potential of Machine Learning (ML) to drive intelligent decision-making, uncover insights, and automate processes. In today's data-rich world, ML has emerged as a transformative force, revolutionizing industries and reshaping the way we interact with technology. Our Machine Learning offerings are designed to help you tap into the extraordinary capabilities of this cutting-edge technology.
        </p>
    </div>
        <div className='aboutampic'>
            <img src={aboutam} alt='aboutam'/>
        </div>
    </div>
  )
}

export default Aimiabout