import React from 'react'
import aboutcs from './Cspics/Aboutcs.png'
import './Csabout.css'

function Csabout() {
  return (
    <div className='cs1-container'>
    <div className='about-cs'>
        <h1>
        TRANSFORM YOUR CLOUD SERVICES FROM HERE
        </h1><br/>
        <p>
        Cloud services encompass a range of solutions and technologies that leverage cloud computing to deliver scalable, flexible, and cost-effective resources and services over the internet. Cloud services have become the cornerstone of innovation, scalability, and agility for businesses across industries.
        </p>
        <p>
        With a bigger view, Cloud Services play a crucial role in enabling organizations to leverage the scalability, flexibility, and agility of cloud computing while ensuring the security, reliability, and performance of their applications and infrastructure. At Technacle, we pride ourselves on our comprehensive capability in delivering Cloud Services, specializing in DevOps, DevSecOps, and Site Reliability Engineering (SRE). 
        </p>
    </div>
        <div className='about-cspic'>
            <img src={aboutcs} alt='aboutcs'/>
        </div>
    </div>
  )
}

export default Csabout