import React, {useEffect} from 'react'
import Iaas from '../Iaas/Iaaspics/iaas.png'
import './Iaas.css'
import Iaasabout from './Iaasabout'
import Iaascapabilities from './Iaascapabilities'
import Clients from '../../../../Clients/Clients'
import Iaaswhatwedo from './Iaaswhatwedo'
// import Iaastestimonials from '../../../../Svrtestimonials/Svrtestimonials'
import Adddigitrans from '../../Adddigitrans/Adddigitrans'
import Footer from '../../../../Footer/Footer'
import Herotestimonials from '../../../../Herotestimonials/Herotestimonials'
import Partners from '../../../../../Components/Partners/Partners'


function Cybersecurity() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);
  return ( 
    <div>
      <div className='iaasheader'>
      <img src={Iaas} alt='Iaas'/>

      <h1> Infrastructure As A Service (IaaS)  </h1>
      <p>
      One of the key features of IaaS is its flexibility.
Businesses can choose to use as much or as little of the infrastructure
as they need and can easily scale up or down based on changing demand
      </p> 
      </div>   
       <Iaasabout/>
       <br/><br/>
       <Herotestimonials/>
       <br/><br/><br/>
      <Iaascapabilities/>
      <Clients/>
      <Iaaswhatwedo/>  
      <Adddigitrans/>
      <Partners/>
      {/* <Iaastestimonials/> */}
      <br/><br/>
      <Footer/>
    </div>
  )
}

export default Cybersecurity

