import React from 'react'
import Cybersecuritypic from './Cybersecurpics/cybersecurity.png'
import './Aboutcyber.css'

function Aboutstaffing() {
  return (
    <div className='aboutstaffcontainer'>
    <div className='aboutstaff'>
        <h1>
        Technacle's Cyber Security Services
        </h1><br/>
        <p>
        Technacle’s mission is to empower organizations to defend against cyber threats and secure their digital assets through innovative solutions, expert guidance, and unparalleled support. We are passionate about protecting our clients' data, privacy, and reputation and helping them navigate the complex cybersecurity landscape with confidence.
        </p>
        <p>
        As a leading provider of cutting-edge cybersecurity solutions, we offer a wide range of cybersecurity services to businesses of all sizes and industries. With a team of highly skilled professionals and a commitment to excellence, we are dedicated to helping organizations mitigate risks, strengthen their security posture, and achieve their cybersecurity objectives.
        </p>
    </div>
        <div className='aboutcyberpic'>
            <img src={Cybersecuritypic} alt='Cybersecuritypic'/>
        </div>
    </div>
  )
}

export default Aboutstaffing