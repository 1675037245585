import React, {useRef} from 'react'
import './Form.css'
import { CiFacebook } from "react-icons/ci";
import { AiFillTwitterCircle } from "react-icons/ai";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
// import { useState } from 'react';
import emailjs from '@emailjs/browser';

function Form() {

  // export const ContactUs = () => {
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm('service_kkgrjgo', 'template_zo02qeb', form.current, {
          publicKey: 'EAf6QyV07cglLakX4',
        })
        .then(
          () => {
            console.log('SUCCESS!');
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );
        e.target.reset();
    };

  return (
    <div  className='addcontainer'>
    <div className='addform'>
    <div className='addressandform'>
    <div>
    <div className='timings'>
        <h1> Opening Hours </h1>
        <p>
        Mon - Fri : 09:00 AM - 6:00 PM <br/>
        Saturday, Sunday : Holiday <br/>
        </p>
    </div>
    <div className='form-contactinfo'>

        <div className='address'>
        <h1> Contact Info </h1>
        <p>
        Sy.No.110/1, 2 & 3, 6th Floor, <br/>
        Valence Block, Prestige Technology Park III, <br/>
        Marathahalli Ring Road,Bengaluru-560103 <br/>
        Karnataka, India
        </p>
        <p>
        admin@technacle.in <br/>
        tel:+91-63663 89988 <br/>
        </p>
        </div>
    </div>
   
    <div className='form-socialcontact'>
        <h1>Social Contact</h1>
    <a href="https://www.facebook.com/technacle" className="icon-link">
        <CiFacebook className='icon'/>
    </a>
    <a href="https://twitter.com" className="icon-link">
        <AiFillTwitterCircle className='icon'/>
    </a>
    <a href="https://www.instagram.com/technacle/" className="icon-link">
        <FaInstagram className='icon'/>
    </a>
    <a href="https://www.linkedin.com/company/technacle-it-services/" className="icon-link">
        <FaLinkedin className='icon'/>
    </a>
    </div>
    </div>
</div>

    <div className='form'>
    <div className='tomake'>
        <h2 className='tomakeh2'>
            To make requests for further information, contact us via our social channels. We just need a couple of hours! No more than 2 working days since receiving your issue ticket.
        </h2><br/>
        <div className='formrequest'>
        <h2>Contact Us</h2>
      
       <form ref={form} onSubmit={sendEmail}>
      <label>Name <span className='required'>*</span> </label><br/>
      <input type="text" name="user_name" /><br/><br/>
      <label>Email <span className='required'>*</span> </label><br/>
      <input type="email" name="user_email" /><br/><br/>
      <label>Message <span className='required'>*</span> </label><br/>
      <textarea name="message" /><br/><br/>
      <div className='btnsubmit'>
      <input type="submit" value="Send" className='submitbtn'/><br/><br/>
      </div>
    </form>
          <p className='donot'>
            Do not submit confidential information such as credit card details, mobile and ATM PINs, OTPs, account passwords, etc.
          </p>
      </div>
    </div>
    </div>
    </div>
    </div>
 )
}
export default Form