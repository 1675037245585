import React from 'react'
import './Entwhatwedo.css'
// import { vector } from '../../../../../Assets/Images'
import {admipicone, admipictwo,admipicthree, admipicfour, admipicfive, admipicsix} from './Pics/Jpics'

const Entwhatwedo = () => {
  return (
    <div className='what-container'>

      <div className='whatinnerborder'>
        {/* <img className='whatvectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='whatforyour1'>
        <br /><br /><br /> <h1> What We Do </h1> <br /><br /><br /> 
        </div>
      </div>

      <div className='softwhatcardcontainer'>
        <div className='whatcard'>
        <img src={admipicone} alt='admipicone'></img><br/><br/>
          <h2> Collaborative Development </h2>
          <p>
          Our teams work closely with you to ensure your vision is realized in the final product, with regular updates and involvement throughout the development process.
          </p>
        </div>

        <div className='whatcard'>
        <img src={admipictwo} alt='admipictwo'></img><br/><br/>
          <h2> Quality Assurance </h2>
          <p>
          Rigorous testing and quality checks are integrated into our process, guaranteeing a flawless and reliable user experience.
          </p>
        </div>

        <div className='whatcard'>
        <img src={admipicthree} alt='admipicthree'></img><br/><br/>
          <h2> Deployment & Maintenance </h2>
          <p>
          Our commitment extends beyond deployment. We provide ongoing maintenance and support to keep your software running smoothly.
          </p>
        </div>

        <div className='whatcard'>
        <img src={admipicfour} alt='admipicfour'></img><br/><br/>
          <h2> Scalable Solutions </h2>
          <p>
          As your business evolves, our software solutions evolve with you, ensuring they remain effective, efficient, and aligned with your growth trajectory.
          </p>
        </div>

        <div className='whatcard'>
        <img src={admipicfive} alt='admipicfive'></img><br/><br/>
          <h2> Innovation Partners </h2>
          <p>
          We don't just meet requirements; we exceed expectations. Count on us to provide insights, recommendations, and innovative approaches that elevate your software to the next level.
          </p>
        </div>

        <div className='whatcard'>
        <img src={admipicsix} alt='admipicsix'></img><br/><br/>
          <h2> Strategic Partnership </h2>
          <p>
          We dive deep into your business goals, understanding your needs to provide strategic software solutions that drive real value and competitive advantage.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Entwhatwedo