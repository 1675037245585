import React from 'react'
import './Entwhatwedo.css'
// import { vector } from '../../../../../Assets/Images'
import ReadMore from '../../../../ReadMore'
import {admipicone, admipictwo,admipicthree, admipicfour, admipicfive} from './Pics/Jpics'

const Entwhatwedo = () => {
  return (
    <div className='what-container'>

      <div className='what-innerborder'>
        {/* <img className='what-vectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='what-foryour1'>
        <br /><br /><br /> <h1> What We Do </h1>  <br /><br /><br />
        </div>
      </div>

      <div className='webwhat-cardcontainer'>
        <div className='what-card'>
        <img src={admipicone} alt='admipicone'></img><br/><br/>
          <h2> Custom Web Application Development </h2>
          <ReadMore className='RMore'
          text={'1. We specialize in developing custom web applications tailored to meet the specific requirements and objectives of our clients. 2. Our team leverages a variety of technologies, including HTML5, CSS3, JavaScript, and popular frameworks such as React.js, Angular, and Vue.js, to create dynamic and responsive web applications.'}
          maxLines={3}/>
        </div>

        <div className='what-card'>
        <img src={admipictwo} alt='admipictwo'></img><br/><br/>
          <h2> Mobile App Development </h2>
          <ReadMore className='RMore'
          text={'1. We design and develop native and cross-platform mobile applications for iOS and Android platforms, ensuring optimal performance, usability, and compatibility across devices. 2. Our mobile app development services encompass the entire development lifecycle, from concept and design to development, testing, and deployment.'}
          maxLines={3}/>
        </div>

        <div className='what-card'>
        <img src={admipicthree} alt='admipicthree'></img><br/><br/>
          <h2> E-Commerce Solutions </h2>
          <ReadMore className='RMore'
          text={'1. We offer end-to-end e-commerce solutions, including custom e-commerce website development, integration with payment gateways, shopping cart functionality, and inventory management. 2. Our e-commerce solutions are designed to enhance user experience, increase conversions, and drive online sales for businesses of all sizes.'}
          maxLines={3}/>
        </div>

        <div className='what-card'>
        <img src={admipicfour} alt='admipicfour'></img><br/><br/>
          <h2> Enterprise Application Development </h2>
          <ReadMore className='RMore'
          text={'1. We develop scalable and secure enterprise applications to streamline business processes, improve productivity, and enhance collaboration within organizations. 2. Our enterprise applications are designed to integrate seamlessly with existing systems and workflows, providing a cohesive and efficient IT infrastructure.'}
          maxLines={3}/>
        </div>

        <div className='what-card'>
        <img src={admipicfive} alt='admipicfive'></img><br/><br/>
          <h2> UI/UX Design Services </h2>
          <ReadMore className='RMore'
          text={'1. Our team of skilled designers creates intuitive and visually appealing user interfaces (UI) and user experiences (UX) for web and mobile applications. 2. We focus on usability, accessibility, and aesthetics to deliver engaging and memorable user experiences that drive user engagement and satisfaction.'}
          maxLines={3}/>
        </div>

        {/* <div className='what-card'>
        <img src={admipicsix} alt='admipicsix'></img><br/><br/>
          <h2> Strategic Partnership </h2>
          <p>
          We're not just developers; we're your strategic partners in innovation. Count on us to provide insights, recommendations, and a roadmap to navigate your enterprise's digital transformation.
          </p>
        </div> */}
      </div>
    </div>
  )
}

export default Entwhatwedo