import React from 'react'
import './Saascapabilities.css'
// import { vector } from '../../../../../Assets/Images'
import {picone, pictwo, picthree, picfour, picfive, picsix} from '../../Servicespics/Colorpics'

const Saascapabilities = () => {
  return (
    <div className='container'>

      <div className='innerborder'>
        {/* <img className='vectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='foryour1'>
        <br /><br /><br /><h1> Capabilities</h1><br /><br /><br />
        </div>
      </div>

      <div className='cardcontainer'>
        <div className='card'>
        <img src={picone} alt='saascapone'/><br/>
          <h2> Access Anywhere, Anytime</h2>
          <p>
          Say goodbye to software installations. With our SaaS solutions, access your applications securely from any device with an internet connection. Work on the go without compromising productivity.
          </p>
        </div>

        <div className='card'>
        <img src={pictwo} alt='saascaptwo'/><br/>
          <h2> Automatic Updates </h2>
          <p>
          Keep up with the latest features and enhancements effortlessly. Our SaaS platform ensures that your applications are automatically updated, so you're always using the latest version.
          </p>
        </div>

        <div className='card'>
        <img src={picthree} alt='saascapthree'/><br/>
          <h2> Scalability </h2>
          <p>
          Whether your business grows or experiences seasonal demands, our SaaS solutions scale seamlessly. You'll never have to worry about outgrowing your software—our platform adapts as you do.
          </p>
        </div>

        <div className='card'>
        <img src={picfour} alt='saascapfour'/><br/>
          <h2> Data Security </h2>
          <p>
          Security is our priority. Our SaaS solutions incorporate robust security measures, including encryption, access controls, and data backups, to keep your sensitive information safe.
          </p>
        </div>

        <div className='card'>
        <img src={picfive} alt='saascapfive'/><br/>
          <h2> Collaboration </h2>
          <p>
          Foster collaboration among your teams, no matter where they're located. Our SaaS applications come with built-in collaboration tools, making teamwork efficient and communication seamless.
          </p>
        </div>

        <div className='card'>
        <img src={picsix} alt='saascapsix'/><br/>
          <h2> Customization</h2>
          <p>
          Tailor our SaaS applications to your business needs. With customizable features and settings, you can adapt the software to match your specific workflows and processes.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Saascapabilities