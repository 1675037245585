import React, {useEffect} from 'react'
import aimicoverpic from '../Software Dev/Pics/softwarecoverpic.png'
import './Software.css'
import Entabout from './Entabout'
import Entcapabilities from './Entcapabilities'
import Clients from '../../../../Clients/Clients'
import Entwhatwedo from './Entwhatwedo'
import Adddigitrans from '../../Adddigitrans/Adddigitrans'
// import Enttestimonials from '../../../../Svrtestimonials/Svrtestimonials'
import Footer from '../../../../Footer/Footer'
import Herotestimonials from '../../../../Herotestimonials/Herotestimonials'
import Partners from '../../../../../Components/Partners/Partners'

function Enterprise() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);
  return ( 
    <div>
      <div className='ent-header'>
      <img src={aimicoverpic} alt='entcovrepic'/>

      <h1> Software Development </h1>
      <p>
      Expert software development that transforms ideas into powerful solutions,
      driving innovation and business growth.
      </p> 
      </div>  
      <Entabout/>
      <br/><br/>
      <Herotestimonials/>
      <br/><br/><br/>
      <Entcapabilities/>
      <Clients/>
      <Entwhatwedo/>
      <Adddigitrans/>
      <Partners/>
      {/* <Enttestimonials/> */}
      <br/> <br/>
      <Footer/>
    </div>
  )
}

export default Enterprise

