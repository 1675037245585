import React, {useState} from 'react'
import './ReadMore.css'

const ReadMore = ({ text, maxLines }) => {
    const [isOpen, setIsOpen] = useState(false);

  const toggleReadMore = () => {
    setIsOpen(!isOpen);
  };

  const containerStyle = {
    backgroundColor: isOpen ? 'rgb(245, 245, 245)' : 'transparent',
    transition: 'background-color 0.3s ease',

  }

  const truncateStyle = {
    WebkitLineClamp: maxLines,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    display: '-webkit-box',
  };
  return (
    <div style={containerStyle} className="ReadMore">
      <p style={isOpen ? null : truncateStyle}>
        {text}
      </p>
      <button onClick={toggleReadMore}>
        {isOpen ? 'Read Less' : 'Read More'}
      </button>

    </div>
  )
}

export default ReadMore