import React, { useState } from 'react';
import './Navbar.css';
import { logo } from '../../Assets/Images';
import {Link} from 'react-router-dom'


const Navbar = () => {
  const [showCloudMenu, setShowCloudMenu] = useState(false);

  const toggleCloudMenu = () => {
    setShowCloudMenu(!showCloudMenu);
  };



  // const handleClickOutside = (event) => {
  //   if (!event.target.closest('.dropdown') && !event.target.closest('.dropdown-btn')) {
  //     setShowCloudMenu(false);
  //   }
  // };

  // useEffect(() => {
  //   if (showCloudMenu) {
  //     document.addEventListener('click', handleClickOutside);
  //   } else {
  //     document.removeEventListener('click', handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener('click', handleClickOutside);
  //   };
  // }, [showCloudMenu]);


  return (
    <>
      <nav className="navbar">
        <Link to={'/'}>
        <img className="navlogo" src={logo} alt="logo"></img>
        </Link>
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link to="/">Home</Link>
          </li>
          <li className="nav-item">
            <Link to="/About">About Us</Link>
          </li>
          <li className="nav-item dropdown">
            <button onClick={toggleCloudMenu} className="dropdown-btn">
              Services
            </button>
            {showCloudMenu && (
              <ul className="dropdown-menu">
                <li className="submenu">
                  <button onClick={toggleCloudMenu} className="submenu-btn">
                  Cloud Services
                  </button>
                  <ul className="submenu-items">
                  <li>
                      <Link to="/Cloud">Cloud Management Services</Link>
                    </li>
                    <li>
                      <Link to="/Iaas">Infrastructure as a Service (IaaS)</Link>
                    </li>
                    <li>
                      <Link to="/Paas">Platform as a Service (PaaS)</Link>
                    </li>
                    <li>
                      <Link to="/Saas">Software as a Service (SaaS)</Link>
                    </li>
                    <li>
                      <Link to="/Managedcloud">Managed Cloud Service - AWS, AZURE, GCP, ORACLE</Link>
                    </li>
                    <li>
                      <Link to="/Cloudservice">Cloud Service - Devsecops, SRE</Link>
                    </li> 
                  </ul>
                </li>
                <li className="submenu">
                  <button onClick={toggleCloudMenu} className="submenu-btn">
                  Artificial Intelligence 
                  </button>
                  <ul className="submenu-items">
                  <li>
                      <Link to="/Artificial">Artificial Intelligence</Link>
                    </li>
                    <li>
                      <Link to="/Mlearning">Machine Learning</Link>
                    </li>
                    <li>
                      <Link to="/Aisolutions">AI/MI Solutions, Advanced Analytics & Agile Engineering </Link>
                    </li>
                  </ul>
                  </li>
                  <li className="submenu">
                  <button onClick={toggleCloudMenu} className="submenu-btn">
                  Application Development 
                  </button>
                  <ul className="submenu-items">
                  <li>
                      <Link to="/Application">Application Development</Link>
                    </li>
                    <li>
                      <Link to="/Enterprise">Enterprise Application Development</Link>
                    </li>
                    <li>
                      <Link to="/Software"> Software Development </Link>
                    </li>
                    <li>
                      <Link to="/Webapplication">Web / Mobile Application Development</Link>
                    </li>
                  </ul>
                  </li>
                  <li className="submenu">
                  <button onClick={toggleCloudMenu} className="submenu-btn">
                  Infrastructure Management 
                  </button>
                  <ul className="submenu-items">
                    <li>
                      <Link to="/Cloud & OnPren">Network Managed Services - Cloud & On-Prem</Link>
                    </li>
                    <li>
                      <Link to="/Networksecurity"> Managed Network Security Services </Link>
                    </li>
                    <li>
                      <Link to="/Forescout">Technacle's Strategic Partnership with Forescout</Link>
                    </li>
                    <li>
                      <Link to="/ITinfrasupport">IT - Infrastructure Support and Maintenance</Link>
                    </li>
                    <li>
                      <Link to="/ITinfradesign">IT - Infrastructure Design Implementation and Support </Link>
                    </li>
                  </ul>
                  </li>
                <li>
                  <Link className='submenu1' to="/Digtrans">Digital Transformation</Link>
                </li>
                <li>
                  <Link className='submenu1' to="/Staffing">Staffing</Link>
                </li >
                <li>
                  <Link className='submenu1' to="/Cybersecurity">Cyber Security</Link>
                </li>
              </ul>
            )}
          </li >
            <li className='btn-contact'>
             <Link to='/Contact'>Contact Us</Link> 
            </li>
        </ul>
      </nav>
    </>
  );
};

export default Navbar;