import React from 'react'
import './Aimiwhatwedo.css'
// import { vector } from '../../../../../Assets/Images'
import {aimipicone, aimipictwo,aimipicthree, aimipicfour, aimipicfive, aimipicsix} from './Aimipics/Aimipics'

const Mlwhatwedo = () => {
  return (
    <div className='whatamcontainer'>

      <div className='whataminnerborder'>
        {/* <img className='whatamvectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='whatamforyour1'>
        <br /><br /><br /><h1> What We Do </h1>  <br /><br /><br />
        </div>
      </div>

      <div className='whatamcardcontainer'>
        <div className='whatamcard'>
        <img src={aimipicone} alt='aimipicone'></img><br/><br/>
          <h2> Custom ML Solutions </h2>
          <p>
          We recognize that ML isn't a one-size-fits-all solution. Our experts collaborate with you to design ML strategies that align with your business objectives and challenges.
          </p>
        </div>

        <div className='whatamcard'>
        <img src={aimipictwo} alt='aimipictwo'></img><br/><br/>
          <h2> End-to-End Implementation </h2>
          <p>
          From concept to deployment, we guide you through the entire ML journey. Our expertise spans from data preprocessing and feature engineering to model training and deployment.
          </p>
        </div>

        <div className='whatamcard'>
        <img src={aimipicthree} alt='aimipicthree'></img><br/><br/>
          <h2> Continuous Learning </h2>
          <p>
          ML is a journey of constant improvement. Our solutions are designed to adapt and learn from new data, ensuring they remain effective and relevant over time.
          </p>
        </div>

        <div className='whatamcard'>
        <img src={aimipicfour} alt='aimipicfour'></img><br/><br/>
          <h2> Ethical AI </h2>
          <p>
          We prioritize ethical considerations in ML development. Our solutions adhere to responsible AI practices, ensuring fairness, transparency, and accountability.
          </p>
        </div>

        <div className='whatamcard'>
        <img src={aimipicfive} alt='aimipicfive'></img><br/><br/>
          <h2> Training and Adoption </h2>
          <p>
          Transitioning to ML doesn't have to be complex. We offer training and support to ensure your teams are equipped to leverage ML-powered technologies effectively.
          </p>
        </div>

        <div className='whatamcard'>
        <img src={aimipicsix} alt='aimipicsix'></img><br/><br/>
          <h2> Strategic Collaboration </h2>
          <p>
          We're not just service providers; we're your partners in innovation. Count on us to provide insights, recommendations, and a roadmap for ML-driven growth.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Mlwhatwedo