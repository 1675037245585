import React from 'react'
import aboutam from './Pics/aboutpic.png'
import './Infraabout.css'

function Entabout() {
  return (
    <div className='entcontainer'>
    <div className='aboutent'>
        <h1>
        Transform Your Business With Cutting-edge IT Infrastructure Solutions From Here
        </h1><br/>
        <p>
        Designing an effective IT infrastructure requires a deep understanding of your business goals, operational requirements, and technological landscape. Our experienced architects work closely with you to assess your current infrastructure, identify pain points, and develop a tailored blueprint for your future IT environment. We focus on scalability, security, and efficiency to ensure that your infrastructure supports your growth and innovation initiatives.
        </p>
        <p>
        At Technacle, we revolutionize the traditional approach to IT infrastructure design, implementation, and support by incorporating innovative strategies, cutting-edge technologies, and a customer-centric mindset. Our unique approach sets us apart and ensures that your IT infrastructure not only meets your current needs but also aligns perfectly with your future objectives.
        </p>
    </div>
        <div className='aboutentpic'>
            <img src={aboutam} alt='aboutam'/>
        </div>
    </div>
  )
}

export default Entabout