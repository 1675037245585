import React, {useEffect} from 'react'
import Saaspic from '../Saas/Saaspics/saas.png'
import './Saas.css'
import  Saasabout from './Saasabout'
import Saascapabilities from './Saascapabilities'
import Saaswhatwedo from './Saaswhatwedo'
import Clients from '../../../../Clients/Clients'
import Adddigitrans from '../../Adddigitrans/Adddigitrans'
// import Saastestimonials from '../../../../Svrtestimonials/Svrtestimonials'
import Footer from '../../../../Footer/Footer'
import Herotestimonials from '../../../../Herotestimonials/Herotestimonials'
import Partners from '../../../../../Components/Partners/Partners'


function Saas() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);
  return ( 
    <div>
      <div className='saasheader'>
      <img src={Saaspic} alt='Saaspic'/>

      <h1> Software As A Service (SaaS)
  </h1>
      <p>
      SaaS offers several benefits for businesses and users,
      including reduced costs, increased flexibility, and scalability.
      </p> 
      </div>   
      <Saasabout/>
      <br/><br/>
      <Herotestimonials/>
      <br/><br/><br/>
      <Saascapabilities/>
      <Clients/>  
      <Saaswhatwedo/> 
      <Adddigitrans/>
      <Partners/>
      {/* <Saastestimonials/> */}
      <br/><br/>
      <Footer/>  
    </div>
  )
}

export default Saas

