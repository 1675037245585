import React from 'react'
import './Whatwedostaffing.css'
// import { vector } from '../../../../Assets/Images'
import ReadMore from '../../../ReadMore'
import {staffingone, staffingtwo, staffingthree} from '../Staffing/Staffingpics/Staffingimages'

const Whatwedostaffing = () => {
  return (
    <div className='whatstaffingcontainer'>

      <div className='whatstaffinginnerborder'>
        {/* <img className='vectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='whatstaffingforyour'>
          <h1> What We Do </h1>  <br /><br />
        </div>
      </div>

      <div className='whatstaffingcardcontainer'>
        <div className='whatstaffingcard'>
        <img src={staffingone} alt='staffingone'></img><br/><br/>
          <h2>Temporary Staffing </h2>
          <ReadMore className='txt'
          text={'Our temporary staffing solutions offer flexible workforce solutions to help you meet short-term needs, manage seasonal fluctuations, and complete special projects on time and within budget. Need additional support for seasonal peaks or special projects? Our temporary staffing solutions offer the flexibility you need to scale your workforce up or down as required.'}
          maxLines={3}/>
        </div>

        <div className='whatstaffingcard'>
        <img src={staffingtwo} alt='staffingtwo'></img><br/><br/>
          <h2> Contract Staffing </h2>
          <ReadMore className='txt'
          text={'Hiring contractors can be an effective way to access specialized skills and expertise without the long-term commitment. Our contract staffing services connect you with qualified professionals who can contribute to your projects and initiatives on a temporary basis.'}
          maxLines={3}/>
        </div>

        <div className='whatstaffingcard'>
        <img src={staffingthree} alt='staffingthree'></img><br/><br/>
          <h2> Direct Hire Placement </h2>
          <ReadMore className='txt'
          text={'Finding the right full-time employees is crucial for the long-term success of any business. Our direct hire placement services streamline the recruitment process, allowing you to attract top talent and build a strong team that drives growth and innovation.'}
          maxLines={3}/>
        </div>

        {/* <div className='whatstaffingcard'>
        <img src={staffingfour} alt='staffingfour'></img><br/><br/>
          <h2> Measurable Outcomes</h2>
          <p className='txt'>
          We're not satisfied with vague improvements. Our solutions are designed to deliver measurable outcomes, from increased operational efficiency to enhanced customer engagement.
          </p>
        </div>

        <div className='whatstaffingcard'>
        <img src={staffingfive} alt='staffingfive'></img><br/><br/>
          <h2> Continuous Innovation </h2>
          <p className='txt'>
          Digital Transformation is an ongoing process. We keep you at the forefront of innovation by continuously exploring emerging technologies that can drive your success.
          </p>
        </div>

        <div className='whatstaffingcard'>
        <img src={staffingsix} alt='staffingsix'></img><br/><br/>
          <h2> Strategic Partnership </h2>
          <p className='txt' >
          We're not just vendors; we're partners invested in your success. Count on us to provide insights, recommendations, and a roadmap for sustainable growth.
          </p>
        </div> */}
      </div>
    </div>
  )
}

export default Whatwedostaffing