import React from 'react'
import './Adcapabilities.css'
// import { vector } from '../../../../../Assets/Images'
import {picone, pictwo, picthree, picfour, picfive, picsix} from '../../Servicespics/Colorpics'

const Adcapabilities = () => {
  return (
    <div className='container'>

      <div className='innerborder'>
        {/* <img className='vectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='foryour1'>
        <br /><br /><br /><h1> Capabilities</h1><br /><br /><br />
        </div>
      </div>

      <div className='cardcontainer'>
        <div className='card'>
        <img src={picone} alt='mccapone'/><br/>
          <h2>Custom Application Development </h2>
          <p>
          We create tailor-made applications that align perfectly with your unique business requirements. From design to deployment, our experts ensure that your vision is translated into a seamless and efficient application.
          </p>
          </div>

        <div className='card'>
        <img src={pictwo} alt='mccaptwo'/><br/>
          <h2> Web and Mobile Development </h2>
          <p>
          Whether you need a responsive website or a feature-rich mobile app, we've got you covered. Our development teams specialize in delivering captivating experiences across various platforms.
        </p>
        </div>

        <div className='card'>
        <img src={picthree} alt='mccapthree'/><br/>
          <h2> User-Centric Design </h2>
          <p>
          We put users at the heart of our design process. Our applications are crafted to be intuitive, engaging, and user-friendly, ensuring high adoption rates and customer satisfaction.
        </p>
        </div>

        <div className='card'>
        <img src={picfour} alt='mccapfour'/><br/>
          <h2> Front-End and Back-End Development </h2>
          <p>
          Our full-stack development capabilities encompass both front-end and back-end development, ensuring your applications are not only visually appealing but also robust and functional.
          </p>
          </div>

        <div className='card'>
        <img src={picfive} alt='mccapfive'/><br/>
          <h2> Integration Services </h2>
          <p>
          Seamlessly connect your applications with third-party services, APIs, and databases. Our integration solutions enhance the functionality of your applications and extend their capabilities.
          </p>
        </div>

        <div className='card'>
        <img src={picsix} alt='mccapsix'/><br/>
          <h2> Scalability and Performance </h2>
          <p>
          As your business grows, your applications should grow with you. Our development approach emphasizes scalability and performance optimization to ensure your applications can handle increasing demands.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Adcapabilities