import React, {useEffect} from 'react'
import aimicoverpic from '../Cloudandonpren/Pics/network-managed-services.png'
import './Cloudonpren.css'
import Infraabout from './Infraabout'
import Infracapabilities from './Infracapabilities'
import Clients from '../../../../Clients/Clients'
import Infrawhatwedo from './Infrawhatwedo'
import Adddigitrans from '../../Adddigitrans/Adddigitrans'
// import Testimonials from '../../../../Svrtestimonials/Svrtestimonials'
import Footer from '../../../../Footer/Footer'
import Herotestimonials from '../../../../Herotestimonials/Herotestimonials'
import Partners from '../../../../../Components/Partners/Partners'

function Cloudonpren() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);
  return ( 
    <div>
      <div className='entheader'>
      <img src={aimicoverpic} alt='entcovrepic'/>

      <h1>NETWORK MANAGED SERVICES – CLOUD & ON-PREM</h1>
      <p>
      Whether you operate in the cloud or on-premises, our solutions are designed to optimize your network infrastructure, ensuring seamless connectivity, robust security, and maximum performance.
      </p> 
      </div>
      <Infraabout/>
      <br/><br/>
      <Herotestimonials/>
      <br/><br/><br/>
      <Infracapabilities/>
      <Clients/>
      <Infrawhatwedo/>
      <Adddigitrans/>
      <Partners/>
      {/* <Testimonials/> */}
      <br/> <br/>
      <Footer/>  
    </div>
  )
}

export default Cloudonpren

