import React, {useEffect} from 'react'
import aibanner from './aipics/aibanner.png'
import './Artificialintelligence.css'
import Aiabout from './Aiabout'
import Herotestimonials from '../../../../Herotestimonials/Herotestimonials'
import Aicapabilities from '../Artificialintelligence/Aicapabilities'
import Clients from '../../../../Clients/Clients'
import Aiwhatwedo from './Aiwhatwedo'
import Adddigitrans from '../../Adddigitrans/Adddigitrans'
import Partners from '../../../../../Components/Partners/Partners'
import Footer from '../../../../Footer/Footer'


function Artificialintelligence() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);
  return ( 
    <div>
      <div className='mc-header'>
      <img src={aibanner} alt='aibanner'/>

      <h1> Artificial Intelligence </h1>
      <p>
      AI also has the potential to improve healthcare,
      by analyzing medical data to identify patterns and predict outcomes,
      and assisting with diagnosis and treatment planning
      </p> 
      </div>
      <br/><br/>
      <Aiabout/>
      <br/><br/>
      <Herotestimonials/>
      <br/><br/>
      <Aicapabilities/>
      <Clients/>
      <Aiwhatwedo/>
      <Adddigitrans/>
      <Partners/>
      <Footer/>
    </div>
  )
}

export default Artificialintelligence

