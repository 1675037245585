import React from 'react'
import aboutam from './Pics/aboutpic.png'
import './Infraabout.css'

function Entabout() {
  return (
    <div className='entcontainer'>
    <div className='aboutent'>
        <h1>
        Your Technacle's Strategic Partnership with Forescout starts here
        </h1><br/>
        <p>
        One such groundbreaking alliance is between Technacle, a leading provider of cutting-edge technology solutions, and Forescout Technologies, a renowned cybersecurity company specializing in device visibility and control.
        </p>
        <p>
        This partnership marks a significant milestone in the realm of cybersecurity, promising top-notch services to safeguard organizations against increasingly sophisticated cyber threats.
        </p>
    </div>
        <div className='aboutinfpic'>
            <img src={aboutam} alt='aboutam'/>
        </div>
    </div>
  )
}

export default Entabout