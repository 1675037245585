import React from 'react'
import aboutpaas from './Paaspics/aboutpaas.png'
import './Paasabout.css'

function Paasabout() {
  return (
    <div className='paascontainer'>
    <div className='aboutpaas'>
        <h1>
        Your Platform as a Service (PaaS) starts here
        </h1><br/>
        <p>
        Platform as a Service (PaaS) is a cloud computing model that provides a complete platform for developers to build, deploy, and manage applications over the internet. With PaaS, developers can focus on developing their applications, while the underlying infrastructure and management tools are provided by the cloud service provider.
        </p>
        <p>
        At Technacle IT Services, we specialize in providing cutting-edge Platform as a Service (PaaS) solutions designed to empower businesses with streamlined application development and deployment. In today's fast-paced digital landscape, accelerating your software development lifecycle while maintaining efficiency and innovation is paramount. Our PaaS offerings are designed to help you achieve just that.
        </p>
    </div>
        <div className='aboutpaaspic'>
            <img src={aboutpaas} alt='aboutpaas'/>
        </div>
    </div>
  )
}

export default Paasabout