import React from 'react'
import './Capabilitiescyber.css'
// import { vector } from '../../../../Assets/Images'
import {picone, pictwo, picthree, picfour, picfive, picsix} from '../Servicespics/Colorpics'
import ReadMore from '../../../ReadMore'

const Topicsdigi = () => {
  return (
    <div className='entcapcontainer'>

      <div className='entcapinnerborder'>
        {/* <img className='entvictorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='entcapforyour1'>
        <br /><br /><br /><h1> Capabilities</h1><br /><br /><br />
        </div>
      </div>

      <div className='entcapcardcontainer1'>
        <div className='cybcapcard'>
        <img src={picone} alt='Cyberfive'/><br/>
          <h2> Expertise and Experience</h2>
          <ReadMore className='txt'
          text={'Our team comprises cybersecurity professionals with extensive expertise and experience in the field. From certified ethical hackers and security analysts to incident responders and compliance specialists, our experts possess the knowledge and skills needed to address the most complex cybersecurity challenges.'}
          maxLines={4}/>
        </div>

        <div className='cybcapcard'>
        <img src={pictwo} alt='Cybersix'/><br/>
          <h2> Comprehensive Solutions</h2>
          <ReadMore className='txt'
          text={'We offer a full spectrum of cybersecurity solutions designed to address various aspects of security, including risk management, threat detection and response, compliance assurance, and security awareness training. Whether you need assistance with vulnerability assessments, penetration testing, or incident response, we have the capabilities to meet your needs.'}
          maxLines={4}/>
        </div>

        <div className='cybcapcard'>
        <img src={picthree} alt='Cybereight'/><br/>
          <h2>Proactive Approach</h2>
          <ReadMore className='txt'
          text={'We take a proactive approach to cybersecurity, helping organizations identify and mitigate vulnerabilities before they can be exploited by cyber attackers. Our proactive services include regular security assessments, penetration testing, and security posture reviews to ensure that your defenses are robust and up-to-date.'}
          maxLines={4}/>
        </div>

        <div className='cybcapcard'>
        <img src={picfour} alt='Cyberenine'/><br/>
          <h2>Tailored services</h2>
          <ReadMore className='txt'
          text={'We understand that every organization has unique security requirements and challenges. That is why we offer tailored cybersecurity solutions that are customized to align with your specific business objectives, industry regulations, and risk tolerance. Whether you are a small business or a large enterprise, we can develop a solution that meets your needs and budget.'}
          maxLines={4}/>
        </div>

        <div className='cybcapcard'>
        <img src={picfive} alt='Cyberfive'/><br/>
          <h2> Cutting-Edge Technology</h2>
          <ReadMore className='txt'
          text={'We leverage cutting-edge cybersecurity technologies and tools to enhance our capabilities and deliver superior results for our clients. From advanced threat detection systems and endpoint protection solutions to security analytics platforms and automation tools, we stay ahead of the curve to keep your organization secure.'}
          maxLines={4}/>
        </div>

        <div className='cybcapcard'>
        <img src={picsix} alt='Cybersix'/><br/>
          <h2> Continuous Improvement</h2>
          <ReadMore className='txt'
          text={'Cybersecurity is an ever-evolving field, and new threats emerge regularly. We are committed to staying at the forefront of cybersecurity trends and best practices to ensure that our clients receive the highest level of protection. We continuously invest in training, research, and development to enhance our capabilities and deliver innovative solutions.'}
          maxLines={4}/>
        </div>

        <div className='cybcapcard'>
        <img src={picone} alt='Cyberone'/><br/>
          <h2> Client-Centric Approach</h2>
          <ReadMore className='txt'
          text={'At the heart of our capability is our unwavering commitment to our clients success. We prioritize client satisfaction and strive to exceed expectations in every engagement. From initial consultation to ongoing support and maintenance, we provide exceptional service and support to our clients.'}
          maxLines={4}/>
        </div>
      </div>
    </div>
  )
}

export default Topicsdigi