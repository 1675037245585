import React from 'react'
import aboutcm from './Cmpics/Aboutcm.png'
import './Cmabout.css'

function Csabout() {
  return (
    <div className='cs-container1'>
    <div className='aboutcs'>
        <h1>
        Your Cloud Management Transformation starts here
        </h1><br/>
        <p>
        At Technacle IT Services, we specialize in providing cutting-edge Cloud Management Services tailored to meet the unique needs of modern businesses. As businesses increasingly migrate their operations to the cloud, effective management becomes crucial for maximizing the benefits of cloud technology while ensuring security, scalability, and cost-efficiency. That's where we come in.
        </p>
        <p>
        In today's rapidly evolving digital landscape, cloud technology has revolutionized the way businesses operate. However, harnessing the full power of the cloud requires more than just migrating data and applications. It demands efficient management to ensure optimal performance, security, and cost-effectiveness. This is where Cloud Management comes into play.
        </p>
    </div>
        <div className='about-cspic'>
            <img src={aboutcm} alt='aboutcs'/>
        </div>
    </div>
  )
}

export default Csabout