import React from 'react'
// import { withGoogleMap, GoogleMap, withScriptjs } from 'react-google-maps';
import './Map.css'
import logo from '../../Assets/Logo.png'
  
function Map() {
   return (
   <div className='mapabouttech'>
    <div style={{ height: '400px', width: '100%' }}> 
    <iframe
          title="Technacle IT Services Location"
          width="100%"
          height="400"
          style={{ border: 0 }}
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15553.986803367528!2d77.6894904!3d12.940037!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae11fd43d22297%3A0xfe8e0271682f6689!2sTechnacle%20IT%20Services%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1722082003391!5m2!1sen!2sin"
          allowFullScreen=""
        ></iframe>
     </div>
     <div className='techproductrs'>
         <img src={logo} alt='logo'/>
      <p>
      Technacle Specializes In <br/>
      Technological And <br/>
      IT-Related Services Such As <br/>
      Digital Transformation,<br/>
      Cloud Management, <br/>
      Infrastructure Support, <br/>
      Artificial Intelligence, Etc.<br/>
      </p>
     </div>   
</div>
   )
}

export default Map


