import React from 'react'
import './Mcwhatwedo.css'
// import { vector } from '../../../../../Assets/Images'
import {mccapone, mccaptwo,mccapthree, mccapfour, mccapfive} from './Mcpics/Mcpics'
import ReadMore from '../../../../ReadMore'

const Mcwhatwedo = () => {
  return (
    <div className='whatmccontainer'>

      <div className='whatmcinnerborder'>
        {/* <img className='whatmcvectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='whatmcforyour1'>
        <br /><br /><br /><h1> What We Do </h1>  <br /><br /><br />
        </div>
      </div>

      <div className='whatmccardcontainer'>
        <div className='whatmccard'>
        <img src={mccapone} alt='mccapone'></img><br/><br/>
          <h2> Cloud Migration and Deployment </h2>
          <ReadMore className='readp'
          text={'Seamlessly migrate your applications, data, and workloads to the cloud with minimal disruption to your business operations. We handle everything from planning and execution to post-migration optimization and support.'}
          maxLines={4}/>
        </div>

        <div className='whatmccard'>
        <img src={mccaptwo} alt='mccaptwo'></img><br/><br/>
          <h2> Cloud Infrastructure Management </h2>
          <p>
          From virtual machines and storage to networking and databases, we manage all aspects of your cloud infrastructure to ensure optimal performance, reliability, and security.
          </p>
        </div>

        <div className='whatmccard'>
        <img src={mccapthree} alt='mccapthree'></img><br/><br/>
          <h2> Cloud Security and Compliance </h2>
          <ReadMore className='readp'
          text={'Protect your cloud environment from cyber threats, data breaches, and compliance violations with our comprehensive security and compliance solutions. We implement industry best practices and cutting-edge technologies to keep your cloud assets safe and compliant.'}
          maxLines={4}/>
        </div>

        <div className='whatmccard'>
        <img src={mccapfour} alt='mccapfour'></img><br/><br/>
          <h2> Cloud Backup and Disaster Recovery</h2>
          <ReadMore className='readp'
          text={'Safeguard your critical data and applications against data loss, corruption, and downtime with our cloud backup and disaster recovery services. We create robust backup plans and recovery strategies to ensure business continuity and resilience in the face of any disaster.'}
          maxLines={4}/>
        </div>

        <div className='whatmccard'>
        <img src={mccapfive} alt='mccapfive'></img><br/><br/>
          <h2> Cloud Cost Optimization </h2>
          <ReadMore className='readp'
          text={'Maximize the value of your cloud investment by optimizing your spending and resource utilization. We analyze your cloud usage, identify cost-saving opportunities, and implement cost optimization strategies to help you achieve your financial goals without sacrificing performance or functionality.'}
          maxLines={4}/>
        </div>

        {/* <div className='whatmccard'>
        <img src={mccapsix} alt='mccapsix'></img><br/><br/>
          <h2> Performance Optimization </h2>
          <p>
          We keep a watchful eye on your resources, ensuring optimal performance. Whether it's fine-tuning virtual machines or optimizing storage, we're dedicated to delivering a superior user experience.
          </p>
        </div> */}
      </div>
    </div>
  )
}

export default Mcwhatwedo