import React from 'react'
import './Whatwedocyber.css'
// import { vector } from '../../../../Assets/Images'
import { Cyberone, Cybertwo, Cyberthree} from './Cybersecurpics/Cybersecurpics'
import ReadMore from '../../../ReadMore'


  const Whatwedocyber = () => {

  return (   
    <div className='whatcybercontainer'>
      <div className='whatcyberinnerborder'>
        {/* <img className='whatcybervectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='whatcyberforyour'>
        <br /><br /><br /><h1> What We Do </h1>  <br /><br /><br />
          
        </div>
      </div>

      <div className='whatcybercardcontainer'>
        <div className='whatcybercard'>
        <img src={Cyberone} alt='cyberone'></img><br/><br/>
          <h2> Cyber Security Assessment </h2>
          <ReadMore className='txt'
           text = {'Our cybersecurity risk assessment services help organizations identify and prioritize potential risks to their IT infrastructure, data assets, and business operations. We provide actionable recommendations to mitigate risks and enhance security resilience.This stream involves assessing and managing cybersecurity risks within an organization. Services may include conducting risk assessments, identifying potential threats and vulnerabilities, evaluating the impact of risks, and developing risk management strategies to mitigate them effectively. Data Protection and Encryption: Data protection services aim to safeguard sensitive data from unauthorized access, disclosure, or theft. This includes implementing encryption, data masking, and data loss prevention (DLP) solutions to secure data at rest, in transit, and in use. Data protection services also involve developing data classification policies and enforcing data privacy regulations.'}
          maxLines={3}/>
        </div>

        <div className='whatcybercard'>
        <img src={Cybertwo} alt='cybertwo'></img><br/><br/>
          <h2> Vulnerabilitiy Management  </h2>
          <ReadMore className='txt'
          text={'Our vulnerability management services help organizations identify, prioritize, and remediate security vulnerabilities in their systems and applications. We offer proactive scanning, patch management, and vulnerability remediation and continuous monitoring to ensure that vulnerabilities are addressed promptly.'}
          maxLines={3}/>
        </div>

        <div className='whatcybercard'>
        <img src={Cyberthree} alt='cyberthree'></img><br/><br/>
          <h2> Security Awareness Training   </h2>
          <ReadMore className='txt'
           text={'We offer comprehensive security awareness training programs to raise awareness among the employees about common cyber threats, educate them about best practices for protecting sensitive information, and their role in maintaining a secure work environment. Our training includes conducting security awareness programs, phishing simulations, and role-based training to help employees recognize and respond to security risks effectively that help organizations build a strong security culture and reduce the risk of human error. Identity and Access Management (IAM): IAM services focus on managing user identities, controlling access to sensitive data and resources, and ensuring compliance with security policies and regulations. This includes implementing authentication mechanisms, access controls, single sign-on (SSO) solutions, and identity governance frameworks to protect against unauthorized access and insider threats. Compliance and Regulatory Services: Compliance and regulatory services help organizations comply with industry-specific regulations and standards, such as GDPR, HIPAA, PCI DSS, and ISO 27001. This includes conducting compliance assessments, developing compliance frameworks, and assisting with regulatory reporting and audits to ensure adherence to legal and regulatory requirements.'}
           maxLines={3}/>
        </div>

        {/* <div className='whatcybercard'>
        <img src={Cyberfour} alt='cyberfour'></img><br/><br/>
          <h2> Measurable Outcomes</h2>
          <p className='txt'>
          We're not satisfied with vague improvements. Our solutions are designed to deliver measurable outcomes, from increased operational efficiency to enhanced customer engagement.
          </p>
        </div>

        <div className='whatcybercard'>
        <img src={Cyberone} alt='cyberone'></img><br/><br/>
          <h2> Continuous Innovation </h2>
          <p className='txt'>
          Digital Transformation is an ongoing process. We keep you at the forefront of innovation by continuously exploring emerging technologies that can drive your success.
          </p>
        </div>

        <div className='whatcybercard'>
        <img src={Cybertwo} alt='cybertwo'></img><br/><br/>
          <h2> Strategic Partnership </h2>
          <p className='txt'>
          We're not just vendors; we're partners invested in your success. Count on us to provide insights, recommendations, and a roadmap for sustainable growth.
          </p>
        </div> */}
      </div>
    </div>
  )
}

export default Whatwedocyber