import React, {useEffect} from 'react'
import contactpic from './contactpic.png'
import './Contactus.css'
import Map from './Map'
import Form from './Form'
// import Adddigitrans from '../Navbar/Services/Adddigitrans/Adddigitrans'
import Footer from '../Footer/Footer'



function Contactus() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);
  return (
    <div>
    <div className='contact-container'>
        <img className='contpic' src={contactpic} alt='contactpic'/>
        <h1> CONTACT US </h1>
    </div>
    <br/><br/>
    <Map/>
    <Form/>
    {/* <Adddigitrans/> */}
    <br/><br/><br/>
    <Footer/>
    </div>
  )
}

export default Contactus