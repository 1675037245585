import React from 'react'
import './Entcapabilities.css'
// import { vector } from '../../../../../Assets/Images'
import {picone, pictwo, picthree, picfour, picfive, picsix} from '../../Servicespics/Colorpics'

const Entcapabilities = () => {
  return (
    <div className='ent-capcontainer'>

      <div className='ent-capinnerborder'>
        {/* <img className='ent-vectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='ent-capforyour1'>
        <br /><br /><br /> <h1> Capabilities</h1><br /><br /><br />
        </div>
      </div>

      <div className='ent-capcardcontainer'>
        <div className='ent-capcard'>
        <img src={picone} alt='admipicone'/><br/>
          <h2> Custom Software Solutions </h2>
          <p>
          We craft bespoke software tailored to your unique requirements, aligning technology with your business goals to enhance operations, efficiency, and customer experiences.</p>
        </div>

        <div className='ent-capcard'>
        <img src={pictwo} alt='admipictwo'/><br/>
          <h2> Full-Stack Development </h2>
          <p>
          Our teams are skilled in both front-end and back-end technologies, ensuring holistic software solutions that are not only visually appealing but also robust and functional.
          </p>
        </div>

        <div className='ent-capcard'>
        <img src={picthree} alt='admipicthree'/><br/>
          <h2> Mobile App Development </h2>
          <p>
          From iOS to Android, we create engaging mobile applications that bring your brand closer to users, fostering engagement and providing valuable solutions on the go.
          </p>
        </div>

        <div className='ent-capcard'>
        <img src={picfour} alt='admipicfour'/><br/>
          <h2> Web Application Development </h2>
          <p>
          We build responsive and user-centric web applications that deliver seamless experiences across devices, making interactions with your platform effortless.
          </p>
        </div>

        <div className='ent-capcard'>
        <img src={picfive} alt='admipicfive'/><br/>
          <h2> Legacy System Modernization </h2>
          <p>
          We breathe new life into legacy systems by transforming them with modern technologies, improving performance, scalability, and user satisfaction.
          </p>
        </div>

        <div className='ent-capcard'>
        <img src={picsix} alt='admipicsix'/><br/>
          <h2> Agile Methodology</h2>
          <p>
          Our agile approach ensures transparent communication, iterative development, and continuous feedback, resulting in software that adapts to changing requirements.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Entcapabilities