import React from 'react'
import aboutiaas from './Iaaspics/aboutiaas.png'
import './Iaasabout.css'

function Iaasabout() {
  return (
    <div className='iaascontainer'>
    <div className='aboutiaas'>
        <h1>
        Your Infrastructure as a Service (IaaS) starts here
        </h1><br/>
        <p>
        Infrastructure as a Service (IaaS) is a cloud computing service that provides basic computing infrastructure, such as virtual machines, storage, and networking. IaaS allows you to rent the underlying hardware and software resources you need to run your applications, without having to purchase or manage the physical equipment yourself.
        </p>
        <p>
        At Technacle IT Services, we specialize in providing top-tier Infrastructure as a Service (IaaS) solutions tailored to meet the unique needs of modern businesses. In today's dynamic digital landscape, having the right IT infrastructure is crucial for ensuring operational efficiency, scalability, and cost-effectiveness. That's where our IaaS offerings come into play.
        </p>
    </div>
        <div className='aboutiaaspic'>
            <img src={aboutiaas} alt='aboutiaas'/>
        </div>
    </div>
  )
}

export default Iaasabout