import React from 'react'
import './Iaascapabilities.css'
// import { vector } from '../../../../../Assets/Images'
import {picone, pictwo, picthree, picfour, picfive, picsix} from '../../Servicespics/Colorpics'
import ReadMore from '../../../../ReadMore'

const Topicsdigi = () => {
  return (
    <div className='iaascapcontainer'>

      <div className='iaascapinnerborder'>
        {/* <img className='cybervectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='iaascapforyour'>
        <br /><br /><br /> <h1> Capabilities</h1><br /><br /><br />
        </div>
      </div>

      <div className='iaascapcardcontainer'>
        <div className='iaascapcard'>
        <img src={picone} alt='iaascapone'/><br/>
          <h2> Virtualized Computing</h2>
          <p>
          Leverage our advanced virtualization technologies to create and manage virtual machines on demand. Scale resources up or down based on your needs, optimizing performance without the hassle of physical hardware.
          </p>
        </div>

        <div className='iaascapcard'>
        <img src={pictwo} alt='iaascaptwo'/><br/>
          <h2> Storage Solution </h2>
          <p>
          Say goodbye to storage constraints. With our IaaS, access scalable and high-performance storage solutions for your data, whether it's structured databases or unstructured files.
          </p>
        </div>

        <div className='iaascapcard'>
        <img src={picthree} alt='iaascapthree'/><br/>
          <h2> Load Balancing and Auto-Scaling </h2>
          <ReadMore 
          text={'Keep your applications responsive even under heavy traffic. Our load balancing and auto-scaling features ensure that resources are distributed efficiently, maintaining performance without manual intervention.'}
          maxLines={4}/>
        </div>

        <div className='iaascapcard'>
          <img src={picfour} alt='iaascapfour'/><br/>
          <h2>Backup and Disaster Recovery </h2>
          <p>
          Safeguard your data with automated backup and disaster recovery solutions. With regular backups and failover options, your business can remain resilient even in the face of unexpected events.
          </p>
        </div>

        <div className='iaascapcard'>
          <img src={picfive} alt='iaascapfive'/><br/>
          <h2> Security Measures </h2>
          <p>
          We take security seriously. Our IaaS solutions incorporate advanced security features, including firewalls, encryption, and identity and access management, to protect your assets from threats.
          </p>
        </div>

        <div className='iaascapcard'>
          <img src={picsix} alt='iaascapsix'/><br/>
          <h2> Cost Management</h2>
          <p>
          Achieve cost predictability with our pay-as-you-go pricing model. No need to invest in upfront hardware costs—pay only for the resources you use.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Topicsdigi