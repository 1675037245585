import React from 'react'
import './Cswhatwedo.css'
// import { vector } from '../../../../../Assets/Images'
import ReadMore from '../../../../ReadMore'
import {mccapone, mccaptwo,mccapthree, mccapfour, mccapfive, mccapsix} from './Cspics/Mcpics'

const Cswhatwedo = () => {
  return (
    <div className='whatcscontainer'>

      <div className='whatcsinnerborder'>
        {/* <img className='whatcsvectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='whatcsforyour1'>
        <br /><br /><br /> <h1> What We Do </h1>  <br /><br /><br />
        </div>
      </div>

      <div className='whatcscardcontainer'>
        <div className='whatcscard'>
        <img src={mccapone} alt='mccapone'></img><br/><br/>
          <h2> DevOps: Streamlined Development and Operations </h2>
          <ReadMore className='RMore'
          text={'Embrace a culture of collaboration and agility with our DevOps services. We facilitate seamless communication and integration between development and operations teams, enabling rapid software delivery and continuous improvement. Our DevOps experts leverage automation, CI/CD pipelines, and infrastructure as code (IaC) to accelerate development cycles, enhance quality, and reduce time-to-market. Whether you are migrating to the cloud or optimizing your existing cloud infrastructure, our DevOps solutions empower you to innovate with confidence.'}
          maxLines={4}/>
        </div>

        <div className='whatcscard'>
        <img src={mccaptwo} alt='mccaptwo'></img><br/><br/>
          <h2> Collaborative Culture </h2>
          <p>
          We cultivate a culture of collaboration between development and operations teams, breaking down silos and promoting shared ownership and responsibility.
          </p>
        </div>

        <div className='whatcscard'>
        <img src={mccapthree} alt='mccapthree'></img><br/><br/>
          <h2> Automation </h2>
          <p>
          We leverage automation tools and practices to streamline development pipelines, accelerate deployment cycles, and improve overall efficiency.


          </p>
        </div>

        <div className='whatcscard'>
        <img src={mccapfour} alt='mccapfour'></img><br/><br/>
          <h2> Continuous Integration and Deployment (CI/CD) </h2>
          <p>
          We implement CI/CD pipelines to automate the building, testing, and deployment of software, enabling rapid and reliable releases.
          </p>
        </div>

        <div className='whatcscard'>
        <img src={mccapfive} alt='mccapfive'></img><br/><br/>
          <h2> infrastructure as Code (IaC)  </h2>
          <p>
          We adopt IaC principles to manage infrastructure programmatically, ensuring consistency, scalability, and version control.
          </p>
        </div>

        <div className='whatcscard'>
        <img src={mccapsix} alt='mccapsix'></img><br/><br/>
          <h2> DevSecOps: Integrating Security into Every Step </h2>
          <ReadMore className='RMore'
          text={'Security is paramount in todays threat landscape, which is why we offer DevSecOps services to integrate security into every stage of your software development lifecycle. From threat modeling and vulnerability scanning to secure coding practices and automated security testing, we embed security into your development and deployment pipelines. By adopting a DevSecOps approach, you can identify and mitigate security risks early, achieve compliance with regulatory requirements, and build trust with your customers.'}
          maxLines={4}/>
        </div>

        <div className='whatcscard'>
        <img src={mccapone} alt='mccapone'></img><br/><br/>
          <h2> Shift-Left Security  </h2>
          <p>
          We promote a "security-first" mindset by integrating security practices into the earliest stages of development, from design to deployment.
          </p>
        </div>

        <div className='whatcscard'>
        <img src={mccaptwo} alt='mccaptow'></img><br/><br/>
          <h2> Automated Security Testing </h2>
          <p>
          We implement automated security testing tools and practices to identify and remediate vulnerabilities early in the development process.
          </p>
        </div>

        <div className='whatcscard'>
        <img src={mccapthree} alt='mccapthree'></img><br/><br/>
          <h2> Secure Coding Practices </h2>
          <p>
          We educate developers on secure coding best practices and conduct code reviews to ensure that applications are built with security in mind.
          </p>
        </div>  

        <div className='whatcscard'>
        <img src={mccapfour} alt='mccapfour'></img><br/><br/>
          <h2> Continuous Compliance </h2>
          <p>
          We help organizations achieve and maintain compliance with regulatory requirements by integrating compliance checks into CI/CD pipelines.
          </p>
        </div>  

        <div className='whatcscard'>
        <img src={mccapsix} alt='mccapsix'></img><br/><br/>
          <h2> SRE: Ensuring Reliability and Scalability </h2>
          <ReadMore className='RMore'
          text={'Ensure the reliability and scalability of your cloud-based applications with our Site Reliability Engineering (SRE) services. Our SRE experts leverage automation, monitoring, and incident response practices to maintain high availability, performance, and resilience for your critical systems. By implementing proactive measures and robust monitoring solutions, we detect and resolve issues before they impact your users, minimizing downtime and maximizing uptime. With our SRE services, you can achieve greater operational efficiency, improve user experience, and drive business growth.'}
          maxLines={4}/>
        </div>

        <div className='whatcscard'>
        <img src={mccapfive} alt='mccapfive'></img><br/><br/>
          <h2> Proactive Monitoring </h2>
          <p>
          We implement robust monitoring solutions to track key performance indicators and detect anomalies in real-time.
          </p>
        </div>  

        <div className='whatcscard'>
        <img src={mccapone} alt='mccapone'></img><br/><br/>
          <h2> Incident Management </h2>
          <p>
          We develop incident response plans and conduct tabletop exercises to ensure rapid and effective responses to incidents and outages.
          </p>
        </div>  

        <div className='whatcscard'>
        <img src={mccaptwo} alt='mccaptwo'></img><br/><br/>
          <h2> Automation and Orchestration </h2>
          <p>
          We leverage automation and orchestration tools to automate repetitive tasks, reduce manual intervention, and improve system reliability.
          </p>
        </div>  

        <div className='whatcscard'>
        <img src={mccapthree} alt='mccapthree'></img><br/><br/>
          <h2> Capacity Planning </h2>
          <p>
          We perform capacity planning exercises to forecast resource requirements and ensure that your infrastructure can scale to meet demand.
          </p>
        </div>  



      </div>
    </div>
  )
}

export default Cswhatwedo