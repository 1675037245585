import React from 'react'
import './App.css'
import Contactus from './Components/Contactus/Contactus'
import Aboutus from './Components/Aboutus/Aboutus'
import Itinfradesign from './Components/Navbar/Services/Inframanagment/ITinfradesign/Itinfradesign'
import Itmaintenance from './Components/Navbar/Services/Inframanagment/ITmaintenance/Itmaintenance'
import Forescout from './Components/Navbar/Services/Inframanagment/Forescout/Forescout'
import Secuservices from './Components/Navbar/Services/Inframanagment/Secuservice/Secuservices'
import Webmob from './Components/Navbar/Services/Application Development/Web-Mob App/Webmob'
import Software from './Components/Navbar/Services/Application Development/Software Dev/Software'
import Enterprise from './Components/Navbar/Services/Application Development/Enterprise App/Enterprise'
import Aimi from './Components/Navbar/Services/Artificial Intelligence/AIMI Solutions/Aimi'
import Mlearning from './Components/Navbar/Services/Artificial Intelligence/Machine Learning/Mlearning'
import Cloudservice from './Components/Navbar/Services/Cloud Management/Cloudservice/Cloudservice'
import Managedcloud from './Components/Navbar/Services/Cloud Management/Managedcloud/Managedcloud'
import Paas from './Components/Navbar/Services/Cloud Management/Paas/Paas'
import Saas from './Components/Navbar/Services/Cloud Management/Saas/Saas'
import Staffing from './Components/Navbar/Services/Staffing/Staffing'
import Navbar from './Components/Navbar/Navbar'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Digtrans from './Components/Navbar/Services/Digitrans/Digtrans'
import Home from './Home'
import Cybersecurity from './Components/Navbar/Services/Cybersecurity/Cybersecurity'
import Iaas from './Components/Navbar/Services/Cloud Management/Iaas/Iaas'
import Cloudonpren from './Components/Navbar/Services/Inframanagment/Cloudandonpren/Cloudonpren'
import Cloudmanagement from './Components/Navbar/Services/Cloud Management/Cloudmanagement/Cloudmanagement'
import Artificialintelligence from './Components/Navbar/Services/Artificial Intelligence/Artificialintelligence/Artificialintelligence'
import Applicationdevelopment from './Components/Navbar/Services/Application Development/Applicationdevelopment/Applicationdevelopment'


const App = () => {
  return ( 
  <div> 
  <BrowserRouter>
  <div> 
  <Navbar/> 
  </div>
  <Routes>
  <Route path='/' exact element={<Home/>}/>
  <Route path='/About' exact element={<Aboutus/>}/>
  <Route path='/Digtrans' exact element={<Digtrans/>}/>
  <Route path='/Staffing' exact element={<Staffing/>}/>
  <Route path='/Cybersecurity' exact element={ <Cybersecurity/>}/>
  <Route path='/Cloud' exact element={ <Cloudmanagement/>}/>
  <Route path='/Artificial' exact element={ <Artificialintelligence/>}/> 
  <Route path='/Application' exact element={ <Applicationdevelopment/>}/>
  <Route path='/Iaas' exact element={<Iaas/>}/>
  <Route path='/Saas' exact element={<Saas/>}/>
  <Route path='/Paas' exact element={<Paas/>}/>
  <Route path='/Managedcloud' exact element={<Managedcloud/>}/>
  <Route path='/Cloudservice' exact element={<Cloudservice/>}/>
  <Route path='/Mlearning' exact element={<Mlearning/>}/>
  <Route path='/Aisolutions' exact element={<Aimi/>}/>
  <Route path='/Enterprise' exact element={<Enterprise/>}/>
  <Route path='/Software' exact element={<Software/>}/>
  <Route path='/Webapplication' exact element={<Webmob/>}/>
  <Route path='/Cloud & OnPren' exact element={<Cloudonpren/>}/>
  <Route path='/Networksecurity' exact element={<Secuservices/>}/>  
  <Route path='/Forescout' exact element={<Forescout/>}/>
  <Route path='/ITinfrasupport' exact element={<Itmaintenance/>}/>
  <Route path='/ITinfradesign' exact element={<Itinfradesign/>}/>
  <Route path='/Contact' exact element={<Contactus/>}/>
  </Routes>
  </BrowserRouter>   
  </div>  
  )
}

export default App
 