import React from 'react'
import aboutapp from './adpics/appabout.png'
import './Adabout.css'

function Aiabout() {
  return (
    <div className='cscontainer'>
    <div className='aboutcs'>
        <h1>
        Your Application Development transformation starts here
        </h1><br/>
        <p>
        Application development is the process of designing, building, testing, and deploying software applications. In today's fast-paced business environment, software applications are critical to the success of any organization. Applications are used to automate business processes, streamline operations, and improve customer experiences.
        </p>
        <p>
        At Technacle IT Services, we specialize in creating impactful and innovative applications that drive business growth, enhance user experiences, and streamline operations. In today's dynamic digital landscape, a well-crafted application is more than just code; it's a gateway to engaging customers, optimizing processes, and staying ahead of the competition. Our Application Development offerings are designed to transform your ideas into reality.
        </p>
    </div>
        <div className='aboutcspic'>
            <img src={aboutapp} alt='aboutapp'/>
        </div>
    </div>
  )
}

export default Aiabout