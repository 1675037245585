import React, {useEffect} from 'react'
import Paaspic from './Paaspics/paas.png'
import './Paas.css'
import  Paasabout from './Paasabout'
import Paascapabilities from './Paascapabilities'
import Paaswhatwedo from './Paaswhatwedo'
import Clients from '../../../../Clients/Clients'
import Adddigitrans from '../../Adddigitrans/Adddigitrans'
// import Paastestimonials from '../../../../Svrtestimonials/Svrtestimonials'
import Footer from '../../../../Footer/Footer'
import Herotestimonials from '../../../../Herotestimonials/Herotestimonials'
import Partners from '../../../../../Components/Partners/Partners'



function Saas() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);
  return ( 
    <div>
      <div className='paasheader'>
      <img src={Paaspic} alt='paaspic'/>

      <h1> Platform As A Service (PaaS) </h1>
      <p>
      PaaS offers several benefits for developers and businesses,
      including increased productivity, reduced time-to-market, and cost savings
      </p> 
      </div>  
      <Paasabout/>
      <br/><br/>
      <Herotestimonials/>
      <br/><br/><br/>
      <Paascapabilities/>
      <Clients/>  
      <Paaswhatwedo/> 
      <Adddigitrans/>
      <Partners/>
      {/* <Paastestimonials/> */}
      <br/><br/>
      <Footer/>  
    </div>
  )
}

export default Saas

