import React from 'react'
import './Infracapabilities.css'
// import { vector } from '../../../../../Assets/Images'
import ReadMore from '../../../../ReadMore'
import {picone, pictwo, picthree, picfour, picfive, picsix} from '../../Servicespics/Colorpics'

const Infracapabilities = () => {
  return (
    <div className='entcapcontainer'>

      <div className='entcapinnerborder'>
        {/* <img className='entvectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='entcapforyou1'>
        <br /><br /><br /> <h1> Key Benefits for Clients</h1><br /><br /><br />
        </div>
      </div>

      <div className='entcapcardcontainer'>
        <div className='entcapcard'>
        <img src={picone} alt='admipicone'/><br/>
          <h2> Enhanced Visibility </h2>
          <ReadMore className='txt'
          text={' Forescout is inovative technologies enable organizations to gain complete visibility into all devices connected to their networks, including IoT devices, BYOD endpoints, and operational technology (OT) devices. This granular visibility empowers organizations to identify and assess potential security risks effectively.'}
          maxLines={4}/>
        </div>

        <div className='entcapcard'>
        <img src={pictwo} alt='admipictwo'/><br/>
          <h2> Automated Threat Response </h2>
          <ReadMore className='text'
          text={'Leveraging Forescout automated threat response capabilities, coupled with Technacle expertise in developing intelligent automation solutions, clients can streamline their incident response processes. This ensures swift detection and mitigation of security threats, reducing the risk of data breaches and system compromises.'}
          maxLines={4}/>
        </div>

        <div className='entcapcard'>
        <img src={picthree} alt='admipicthree'/><br/>
          <h2> Comprehensive Compliance </h2>
          <ReadMore className='text'
          text={'Regulatory compliance is a critical concern for businesses across various industries. With Technacle proficiency in compliance management and Forescout robust compliance enforcement features, clients can ensure adherence to industry regulations and standards, such as GDPR, HIPAA, and PCI DSS.'}
          maxLines={4}/>
        </div>

        <div className='entcapcard'>
        <img src={picfour} alt='admipicfour'/><br/>
          <h2> Scalability and Flexibility </h2>
          <ReadMore className='text'
          text={'The combined solutions offered by Technacle and Forescout are designed to scale seamlessly to meet the evolving needs of organizations, regardless of size or complexity. Whether securing a small business network or a multinational enterprise environment, clients can benefit from adaptable and scalable cybersecurity solutions.'}
          maxLines={4}/>
        </div>

        <div className='entcapcard'>
        <img src={picfive} alt='admipicfive'/><br/>
          <h2> Continuous Innovation </h2>
          <ReadMore className='text'
          text={'As technology and cyber threats continue to evolve, the partnership between Technacle and Forescout ensures that clients have access to the latest advancements in cybersecurity. Through ongoing research and development efforts, the two companies are committed to delivering innovative solutions that stay ahead of emerging threats.'}
          maxLines={4}/>
        </div>

        <div className='entcapcard'>
        <img src={picsix} alt='admipicsix'/><br/>
          <h2> Analytics and Monitoring </h2>
          <p className='text'>
          Tracking website traffic and user behavior is essential for optimizing web content. Analytics and monitoring capabilities provide insights into user engagement, search engine optimization, and website performance.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Infracapabilities