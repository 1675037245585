import React from 'react'
import aboutam from './Pics/aboutpic.png'
import './Entabout.css'

function Entabout() {
  return (
    <div className='ent-container'>
    <div className='about-ent'>
        <h1>
        Your Software Development transformation starts here
        </h1><br/>
        <p>
        Software development is the process of creating software applications. This process involves planning, designing, coding, testing, and deploying applications. Software development can be a long and challenging process, but it is also a rewarding one. When done well, software development can create applications that help businesses to improve their efficiency, reach new customers, and provide better products and services.
        </p>
        <p>
        At Technacle IT Services, we specialize in delivering exceptional software development solutions that cater to a wide spectrum of industries and business needs. With our deep technical expertise and commitment to innovation, we bring ideas to life through cutting-edge technologies. Our software development offerings are designed to empower businesses to thrive in the digital age.
        </p>
    </div>
        <div className='about-entpic'>
            <img src={aboutam} alt='aboutam'/>
        </div>
    </div>
  )
}

export default Entabout