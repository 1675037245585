import React, {useEffect} from 'react'
import Mcloud from '../Managedcloud/Mcpics/Mcloud.png'
import './Managedcloud.css'
import Mcabout from './Mcabout'
import Mccapabilities from './Mccapabilities'
import Clients from '../../../../Clients/Clients'
import Mcwhatwedo from './Mcwhatwedo'
// import Mctestimonials from '../../../../Svrtestimonials/Svrtestimonials'
import Adddigitrans from '../../Adddigitrans/Adddigitrans'
import Footer from '../../../../Footer/Footer'
import Herotestimonials from '../../../../Herotestimonials/Herotestimonials'
import Partners from '../../../../../Components/Partners/Partners'


function Managedcloud() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);
  return ( 
    <div>
      <div className='mcloudheader'>
      <img className='mcheaderimg' src={Mcloud} alt='Mcloud'/>

      <h1> MANAGED CLOUD SERVICES – AWS, Azure, GCP, Oracle  </h1>
      <p>
      Unlock the full potential of cloud computing with our comprehensive Network Managed Cloud Services. Whether you're leveraging AWS, Azure, GCP, Oracle Cloud, or a combination thereof, we provide expert solutions to optimize, secure, and manage your cloud network infrastructure. From seamless migration to ongoing monitoring and support, we're here to elevate your cloud experience.
      </p> 
      </div>  
      <Mcabout/>
      <br/><br/>
      <Herotestimonials/>
      <br/><br/><br/>
      <Mccapabilities />
      <Clients/>
      <Mcwhatwedo/>  
      <Adddigitrans/>
      <Partners/>
      {/* <Mctestimonials/> */}
      <br/><br/>
      <Footer/>
    </div>
  )
}

export default Managedcloud

