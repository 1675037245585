import React, {useEffect} from 'react'
import Digtran from './Digipics/digital.png'
import './Digtrans.css'
import Aboutdigi from './Aboutdigi'
import Topicsdigi from './Topicsdigi'
import Whatwedo from './Whatwedo'
import Clients from '../../../Clients/Clients'
import Adddigitrans from '../Adddigitrans/Adddigitrans'
import Partners from '../../../../Components/Partners/Partners'
import Footer from '../../../Footer/Footer'
import Herotestimonials from '../../../Herotestimonials/Herotestimonials'

function Digtrans() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);
  return ( 
    <div>
      <div className='digiheader'>
      <img src={Digtran} alt='Digitran'/>

      <h1>Digital Transformation </h1>
      <p> Digital transformation is driven by several factors,
        including the increasing availability of digital technologies,
        the rise of new business models, and changing customer expectations.
      </p> 
      </div>   
       <Aboutdigi/>
       <br/><br/>
       <Herotestimonials/>
       <br/><br/>
       <Topicsdigi/>
       <Clients/>
       <Whatwedo/>
       <Adddigitrans/>
       <Partners/>
       <br/><br/>
       <Footer/>
    </div>
  )
}

export default Digtrans

