import React from 'react'
import digiaboutpic from '../../../../Assets/digiaboutpic.jpg'
import './Aboutdigi.css'

function Aboutdigi() {
  return (
    <div className='aboutcontainer'>
    <div className='aboutdigital'>
        <h1>
        Your Digital Transformation starts here
        </h1><br/>
        <p>
        Digital transformation is the process of 
        using digital technologies to fundamentally 
        change the way an organization operates. 
        This can include changes to the way an 
        organization delivers its products or 
        services, the way it interacts with its 
        customers, or the way it manages its internal 
        operations.
        </p>
        <p>
              At Technacle IT Services, we specialize in driving
              impactful Digital Transformation strategies that
              propel businesses into the future. In today's
              ever-evolving landscape, staying competitive demands
              more than incremental change—it requires a holistic
              shift that harnesses the power of technology
              to reimagine processes, engage customers, and
              achieve unprecedented levels of efficiency.
              Our Digital Transformation offerings are designed
              to guide you on this transformative journey.
        </p>
    </div>
        <div className='aboutdigipic'>
            <img src={digiaboutpic} alt='digiaboutpic'/>
        </div>
    </div>
  )
}

export default Aboutdigi