import React from 'react'
import './Topicsstaffing.css'
// import { vector } from '../../../../Assets/Images'
import {picone, pictwo, picthree, picfour, picfive} from '../Servicespics/Colorpics'
import ReadMore from '../../../ReadMore'

const Topicsdigi = () => {
  return (
    <div className='Staffing-topiccontainer'>
      <div className='staffing-topic'>

      <div className='staffing-innerborder'>
        {/* <img className='staffing-vectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='staffing-foryour'>
        <br /><br /><h1> Capabilities</h1><br /><br />
        </div>
      </div>

      <div className='staffing-cardcontainer'>
        <div className='staffing-card'>
        <img src={picone} alt='staffing-clrone'/><br/>
          <h2>Industry Expertise</h2>
          <p className='txt'>
          Our team comprises industry experts with in-depth knowledge and experience in various sectors, ensuring that we understand your unique staffing requirements.
          </p>
        </div>

        <div className='staffing-card'>
        <img src={pictwo} alt='staffing-clrtwo'/><br/>
          <h2>Customized Solutions</h2>
          <ReadMore className='txt'
          text={'We take a personalized approach to staffing, tailoring our solutions to align with your business objectives, culture, and values. From temporary staffing and contract placements to direct hire recruitment, we offer a full suite of staffing services to meet your evolving needs and requirements.'}
          maxLines={4}/>
        </div>

        <div className='staffing-card'>
        <img src={picthree} alt='staffingclrthree'/><br/>
          <h2> Quality Candidates</h2>
          <ReadMore className='txt'
          text={'We employ a rigorous screening and vetting process to ensure that all candidates meet our high standards of excellence and possess the skills, qualifications, and values necessary to succeed in their roles. We thoroughly screen and vet all candidates to ensure they possess the skills, qualifications, and attributes needed to excel in their roles.'}
          maxLines={4}/>
        </div>

        <div className='staffing-card'>
        <img src={picfour} alt='staffingclrfour'/><br/>
          <h2>Personalized Approach</h2>
          <p className='txt'>
          We take the time to listen and understand your specific staffing challenges and objectives, allowing us to develop customized solutions that align with your business goals and culture.
          </p>
        </div>

        <div className='staffing-card'>
        <img src={picfive} alt='staffingclrfive'/><br/>
          <h2> Efficiency and Flexibility</h2>
          <p className='txt'> 
          We understand the importance of agility in today's business environment. Whether you need staffing support for short-term projects or long-term initiatives, we're here to help.
          </p>
        </div>

        {/* <div className='staffing-card'>
        <img src={picsix} alt='staffingclrsix'/><br/>
          <h2>Change Management</h2>
          <p className='txt'>
          A successful transformation is about people as much as technology. Our change management strategies empower your workforce to embrace the changes, ensuring a smooth transition.
          </p>
        </div> */}
      </div>
      </div>
    </div>
  )
}

export default Topicsdigi