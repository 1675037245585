import React from 'react'
import './Mlcapabilities.css'
// import { vector } from '../../../../../Assets/Images'
import {picone, pictwo, picthree, picfour, picfive, picsix} from '../../Servicespics/Colorpics'

const Mlcapabilities = () => {
  return (
    <div className='container'>

      <div className='innerborder'>
        {/* <img className='vectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='foryour1'>
        <br /><br /><br /> <h1> Capabilities</h1><br /><br /><br />
        </div>
      </div>

      <div className='cardcontainer'>
        <div className='card'>
        <img src={picone} alt='mlpicone'/><br/>
          <h2> Pattern Recognition</h2>
          <p>
          Uncover hidden patterns and correlations within complex datasets. Our ML algorithms excel at identifying trends that human eyes might miss, enabling data-driven insights.
          </p>
        </div>

        <div className='card'>
        <img src={pictwo} alt='mlpictwo'/><br/>
          <h2> Natural Language Processing (NLP) </h2>
          <p>
          Transform unstructured text data into actionable insights. Our NLP models enable sentiment analysis, language translation, and information extraction.
          </p>
        </div>

        <div className='card'>
        <img src={picthree} alt='mlpicthree'/><br/>
          <h2> Anomaly Detection </h2>
          <p>
          Detect outliers and anomalies in real-time data streams. Our ML solutions provide early warnings for potential issues, from fraud detection to equipment failures.
          </p>
        </div>

        <div className='card'>
        <img src={picfour} alt='mlpicfour'/><br/>
          <h2> Predictive Analytics </h2>
          <p>
          Leverage historical data to predict future trends and outcomes. Our predictive models empower you to make proactive decisions, optimize strategies, and seize opportunities.
          </p>
        </div>

        <div className='card'>
        <img src={picfive} alt='mlpiconefive'/><br/>
          <h2> Image and Video Analysis </h2>
          <p>
          Equip your systems with visual intelligence. Our ML-powered computer vision solutions enable object detection, image classification, and video analytics.
          </p>
        </div>

        <div className='card'>
        <img src={picsix} alt='mlpicsix'/><br/>
          <h2> Recommendation Systems</h2>
          <p>
          Personalize user experiences with recommendation engines. Our ML algorithms analyze user behaviour to provide tailored suggestions and enhance engagement.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Mlcapabilities