import React, {useEffect} from 'react'
import Security from '../Cybersecurity/Cybersecurpics/Security1.png'
import './Cybersecurity.css'
import Aboutcyber from '../Cybersecurity/Aboutcyber'
import Capabilitiescyber from '../Cybersecurity/Capabilitiescyber'
import Whatwedocyber from './Whatwedocyber'
import Clients from '../../../Clients/Clients'
import Footer from '../../../Footer/Footer'
import Herotestimonials from '../../../Herotestimonials/Herotestimonials'
import Adddigitrans from '../Adddigitrans/Adddigitrans'
import Partners from '../../../../Components/Partners/Partners'
// import Cytestimonials from '../../../Svrtestimonials/Svrtestimonials'


function Cybersecurity() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);
  return ( 
    <div>
      <div className='stafheader'>
      <img src={Security} alt='Stafing'/>

      <h1> Cyber Security  </h1>
      <p>
      With cyber threats evolving at an unprecedented pace, protecting your organization's sensitive data, systems, and assets is more crucial than ever. Our cybersecurity services offer comprehensive solutions to help safeguard your business from cyber attacks and security breaches.
      </p> 
      </div>   
       <Aboutcyber/>
       <br/><br/>
       <Herotestimonials/>
       <br/><br/><br/>
       <Capabilitiescyber/>
       <Clients/>
       <Whatwedocyber/>
       <Adddigitrans/>
       <Partners/>
       <br/><br/>
       <Footer/>
    </div>
  )
}

export default Cybersecurity

