import React from 'react'
import './Footer.css'
import {logowhite} from '../../Assets/Images'
import { TiSocialFacebook } from "react-icons/ti";
import { TiSocialTwitter } from "react-icons/ti";

import { FaInstagram } from "react-icons/fa";
import { TiSocialLinkedin } from "react-icons/ti";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='mainfooter-container'>
    <div className='mainfooterframe'>
      <div className='contactus1'>
        <p>Unlock your product's full potential</p>
        <Link to='/Contact'>
        <button>Contact Us</button>
        </Link>
      </div>
      <div>
        <div className='contactinfo'>
        <div className='maintech'>
          <div className='techadd'>
          <img src={logowhite} alt='logo'></img>
          <p>Technacle Specializes In <br/>
            Technological And IT-Related <br/>
            Services Such As Digital<br/> 
             Transformation, Cloud <br/>
             Management,Infrastructure <br/>
             Support,Artificial Intelligence, Etc.</p>
             </div>
        </div>
        <div className='maintechlink'>
          <h1>Links</h1>
          <Link style={{ textDecoration: 'none' }} to='/'>
          <p>Home</p>
          </Link>
          <Link style={{ textDecoration: 'none' }} to='/About'>
          <p>About Us</p>
          </Link>
          <Link style={{ textDecoration: 'none' }} to='Contact'>
          <p>Contact Us</p>
          </Link>
        </div>
        <div className='maintech'>
          <h1>Services</h1>
          <Link style={{ textDecoration: 'none' }} to='/Cloud'>
          <p>Cloud Management </p>
          </Link>
          <Link style={{ textDecoration: 'none' }} to='/Artificial'>
          <p> Artificial Intelligence </p>
          </Link>
          <Link style={{ textDecoration: 'none' }} to='/Application'>
          <p>  Application Development </p>
          </Link>
          <Link style={{ textDecoration: 'none' }} to='/Digtrans'>
          <p> Digital Transformation </p>
          </Link>
          <Link style={{ textDecoration: 'none' }} to='/ITinfrasupport'>
          <p>  IT Infrastructure Support </p>
          </Link>
        </div>
        <div className='maintech1'>
          <h1>Contact Info</h1>
          <p>Sy.No.110/1, 2 & 3, 6th Floor, <br/>
             Valence Block,<br/>
             Prestige Technology Park III, <br/>
             Varthur Hobli, <br/>
             Marathahalli Ring Road,<br/>
             Bengaluru-560103, Karnataka, India<br/><br/>
             Mobile : +91-63663 89988<br/>
             Mail Us : admin@technacle.in
             </p>
             <div className='Socialmedia'>
             <a href="https://www.facebook.com/technacle" className="icon-link">
             <TiSocialFacebook className='icon'/>
             </a>
             <a href="https://twitter.com" className="icon-link">
             <TiSocialTwitter  className='icon'/>
             </a>
             <a href="https://www.instagram.com/technacle/" className="icon-link">
             <FaInstagram className='icon'/>
             </a>
             <a href="https://www.linkedin.com/company/technacle-it-services/" className="icon-link">
             <TiSocialLinkedin  className='icon'/>
             </a>
             </div>   
        </div>
      </div>
      </div>
      <div className='thanks'>
      <p>Technacle @ 2024 All Rights Reserved</p>
        <p className='privacy'>Terms of Services | Privacy Policy</p>
      </div>
    </div>
    </div>
  )
}

export default Footer