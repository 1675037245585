import React from 'react'
import './Whatwedo.css'
// import { vector } from '../../../../Assets/Images'
import {changemanagement, customerex, datadriven, digistategy, processop, techimplimentation} from '../Digitrans/Digipics/Imagesdigi'

const Topicsdigi = () => {
  return (
    <div className='topiccontainer'>

      <div className='innerborder'>
        {/* <img className='vectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='foryour1'>
          <h1> What We Do </h1><br /><br />
        </div>
      </div>

      <div className='whatcardcontainer'>
        <div className='card'>
        <img src={digistategy} alt='digital'></img><br/><br/>
          <h2>Tailored Strategies </h2>
          <p>
          We recognize that each business has a unique DNA. Our experts collaborate with you to design Digital Transformation strategies that resonate with your organization's culture, challenges, and aspirations.
          </p>
          {/* <a href='#' style={{color:'darkblue'}}>Read More</a> */}
        </div>

        <div className='card'>
        <img src={processop} alt='artificial'></img><br/><br/>
          <h2> Holistic Approach</h2>
          <p>
          Our approach is comprehensive. We don't just focus on isolated technology upgrades, we consider the entire ecosystem to create harmonious and impactful transformations.
          </p>
          {/* <a href='#' style={{color:'darkblue'}}>Read More</a> */}
        </div>

        <div className='card'>
        <img src={customerex} alt='cloud'></img><br/><br/>
          <h2> Collaboration and Training </h2>
          <p>
          Digital Transformation is a shared journey. We offer collaborative workshops, training, and ongoing support to ensure your teams are equipped to thrive in the digital age.
          </p>
          {/* <a href='#' style={{color:'darkblue'}}>Read More</a> */}
        </div>

        <div className='card'>
        <img src={datadriven} alt='application'></img><br/><br/>
          <h2> Measurable Outcomes</h2>
          <p>
          We're not satisfied with vague improvements. Our solutions are designed to deliver measurable outcomes, from increased operational efficiency to enhanced customer engagement.
          </p>
          {/* <a href='#' style={{color:'darkblue'}}>Read More</a> */}
        </div>

        <div className='card'>
        <img src={techimplimentation} alt='itinfra'></img><br/><br/>
          <h2> Continuous Innovation </h2>
          <p>
          Digital Transformation is an ongoing process. We keep you at the forefront of innovation by continuously exploring emerging technologies that can drive your success.
          </p>
          {/* <a href='#' style={{color:'darkblue'}}>Read More</a> */}
        </div>

        <div className='card'>
        <img src={changemanagement} alt='software'></img><br/><br/>
          <h2> Strategic Partnership </h2>
          <p>
          We're not just vendors; we're partners invested in your success. Count on us to provide insights, recommendations, and a roadmap for sustainable growth.
          </p>
          {/* <a href='#' style={{color:'darkblue'}}>Read More</a> */}
        </div>
      </div>
    </div>
  )
}

export default Topicsdigi