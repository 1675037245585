import React from 'react'
import aboutai from './aipics/Aboutai.png'
import './Aiabout.css'

function Aiabout() {
  return (
    <div className='cs-container'>
    <div className='about-cs'>
        <h1>
        Your Artificial Intelligence transformation starts here
        </h1><br/>
        <p>
        Artificial intelligence (AI) is a branch of computer science that deals with the creation of intelligent agents, which are systems that can reason, learn, and act autonomously. AI research has been highly successful in developing effective techniques for solving a wide range of problems, from game playing to medical diagnosis.
        </p>
        <p>
        At Technacle IT Services, we specialize in harnessing the power of Artificial Intelligence (AI) to drive innovation, efficiency, and transformative solutions. In today's rapidly evolving technological landscape, AI has emerged as a game-changer, revolutionizing industries and reshaping possibilities. Our AI offerings are designed to help you unlock the full potential of this cutting-edge technology.
        </p>
    </div>
        <div className='about-cspic'>
            <img src={aboutai} alt='aboutai'/>
        </div>
    </div>
  )
}

export default Aiabout