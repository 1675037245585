import React, {useEffect} from 'react'
import appbanner from './adpics/application.png'
import './Applicationdevelopment.css'
import Adabout from './Adabout'
import Herotestimonials from '../../../../Herotestimonials/Herotestimonials'
import Adcapabilities from './Adcapabilities'
import Clients from '../../../../Clients/Clients'
import Aiwhatwedo from './Adwhatwedo'
import Adddigitrans from '../../Adddigitrans/Adddigitrans'
import Partners from '../../../../../Components/Partners/Partners'
import Footer from '../../../../Footer/Footer'


function Artificialintelligence() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);
  return ( 
    <div>
      <div className='mcheader'>
      <img src={appbanner} alt='appbanner'/>

      <h1> Application Development </h1>
      <p>
      The application development process involves several phases, including requirements gathering, design, development, testing, deployment, and maintenance.
      </p> 
      </div>
      <Adabout/>
      <br/><br/>
      <Herotestimonials/>
      <br/><br/>
      <Adcapabilities/>
      <Clients/>
      <Aiwhatwedo/>
      <Adddigitrans/>
      <Partners/>
      <Footer/>
      
    </div>
  )
}

export default Artificialintelligence

