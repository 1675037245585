import React, { Component } from "react";
import Carousel from "react-spring-3d-carousel";
import { v4 as uuidv4 } from "uuid";
import { config } from "react-spring";
import { Link } from 'react-router-dom';
import './Herotestimonials.css';

import imageone from './Heropics/ai.png';
import imagetwo from './Heropics/application.png';
import imagethree from './Heropics/cloud.png';
import imagefour from './Heropics/digital.png';
import imagefive from './Heropics/Mlcoverpic.png';
import imagesix from './Heropics/secure.png';

export default class MyCarousel extends Component {
  state = {
    goToSlide: 0,
    offsetRadius: 2,  
    showNavigation: false,
    config: config.gentle,
    autoplay: true,
    autoplayInterval: 3000, 
  };

  slides = [
    {
      key: uuidv4(),
      content: (
        <Link style={{ textDecoration:'none'}} to='/Artificial'>
          <div className='imgcontainer' style={{ width: "60%", height: "320px", margin: "0 auto"}}>
            <img className="carouselimg" src={imageone} alt="1" /> 
            <h3 className="herotitle"> ARTIFICIAL INTELLIGENCE </h3>
            <p className='herotext'>
              AI also has the potential to improve healthcare, by analyzing medical data to identify patterns and predict outcomes, and assisting with diagnosis and treatment planning.
            </p>
          </div>
        </Link>
      )
    },
    {
      key: uuidv4(),
      content: (
        <Link style={{ textDecoration:'none'}} to='/Application'>
          <div className='imgcontainer' style={{ width: "60%", height: "320px", margin: "0 auto"}}>
            <img className="carouselimg" src={imagetwo} alt="2" />
            <h3 className="herotitle"> APPLICATION DEVELOPMENT </h3>
            <p className='herotext'>
              The application development process involves several phases, including requirements gathering, design, development, testing, deployment, and maintenance.
            </p>
          </div>
        </Link>
      )
    },
    {
      key: uuidv4(),
      content: (
        <Link style={{ textDecoration:'none'}} to='/Cloud'>
          <div className='imgcontainer' style={{ width: "60%", height: "320px", margin: "0 auto"}}>
            <img className="carouselimg" src={imagethree} alt="3" />
            <h3 className="herotitle"> CLOUD MANAGEMENT SERVICES </h3>
            <p className='herotext'>
              Envision with Technacle and plan ahead to deploy innovative business solutions for sustainable growth and success
            </p>
          </div>
        </Link>
      )
    },
    {
      key: uuidv4(),
      content: (
        <Link style={{ textDecoration:'none'}} to='/Digtrans'>
          <div className='imgcontainer' style={{ width: "60%", height: "320px", margin: "0 auto"}}>
            <img className="carouselimg" src={imagefour} alt="4" />
            <h3 className="herotitle"> DIGITAL TRANSFORMATION </h3>
            <p className='herotext'>
              Digital transformation is driven by several factors,including the increasing availability of digital technologies, the rise of new business models, and changing customer expectations.
            </p>
          </div>
        </Link>
      )
    },
    {
      key: uuidv4(),
      content: (
        <Link style={{ textDecoration:'none'}} to='/Mlearning'>
          <div className='imgcontainer' style={{ width: "60%", height: "320px", margin: "0 auto"}}>
            <img className="carouselimg" src={imagefive} alt="5" />
            <h3 className="herotitle"> MACHINE LEARNING </h3>
            <p className='herotext'>
              Machine learning also has the potential to improve healthcare, by analyzing medical data to identify patterns and predict outcomes, and assisting with diagnosis and treatment planning.
            </p>
          </div>
        </Link>
      )
    },
    {
      key: uuidv4(),
      content: (
        <Link style={{ textDecoration:'none'}} to='/Cybersecurity'>
          <div className='imgcontainer' style={{ width: "60%", height: "320px", margin: "0 auto"}}>
            <img className="carouselimg" src={imagesix} alt="6" />
            <h3 className="herotitle"> CYBER SECURITY </h3>
            <p className='herotext'>
              With cyber threats evolving at an unprecedented pace, protecting your organization's sensitive data, systems, and assets is more crucial than ever. 
            </p>
          </div>
        </Link>
      )
    },
  ];

  componentDidMount() {
    this.startAutoplay();
  }

  componentWillUnmount() {
    this.stopAutoplay();
  }

  startAutoplay = () => {
    this.autoplayInterval = setInterval(() => {
      const { goToSlide } = this.state;
      this.setState({ goToSlide: (goToSlide + 1) % this.slides.length });
    }, this.state.autoplayInterval);
  };

  stopAutoplay = () => {
    clearInterval(this.autoplayInterval);
  };

  render() {
    return (
      <div className="carousel-container">
        <Carousel
          slides={this.slides}
          goToSlide={this.state.goToSlide}
          offsetRadius={this.state.offsetRadius}
          showNavigation={this.state.showNavigation}
          animationConfig={this.state.config}
          autoplay={this.state.autoplay}
          autoplayInterval={this.state.autoplayInterval}
        />
      </div>
    );
  }
}

