import React from 'react'
import './Infrawhatwedo.css'
// import { vector } from '../../../../../Assets/Images'
import ReadMore from '../../../../ReadMore'
import {admipicone, admipictwo,admipicthree, admipicfour, admipicfive, admipicsix} from './Pics/Jpics'

const Entwhatwedo = () => {
  return (
    <div className='whatcontainer'>

      <div className='whatinnerborder'>
        {/* <img className='whatvectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='whatforyour1'>
        <br /><br /><br /> <h1> What We Do </h1>  <br /><br /><br />
        </div>
      </div>

      <div className='infrawhatcardcontainer'>
        <div className='whatcard'>
        <img src={admipicone} alt='admipicone'></img><br/><br/>
          <h2> Cloud Migration and Integration </h2>
          <ReadMore className='RMore'
          text={'Seamlessly transition your network infrastructure to leading cloud platforms such as AWS, Azure, or Google Cloud with our expert migration and integration services, minimizing disruption and ensuring a smooth transition.'}
          maxLines={3}/>
        </div>

        <div className='whatcard'>
        <img src={admipictwo} alt='admipictwo'></img><br/><br/>
          <h2> Network Design and Implementation </h2>
          <ReadMore className='RMore'
          text={'Design and deploy robust on-premises network solutions tailored to your specific requirements, ensuring optimal performance, scalability, and reliability. Whether you are a small business or a large enterprise, we have the expertise to meet your needs.'}
          maxLines={3}/>
        </div>

        <div className='whatcard'>
        <img src={admipicthree} alt='admipicthree'></img><br/><br/>
          <h2> 24/7 Cloud Monitoring and Management </h2>
          <ReadMore className='RMore'
          text={'Our dedicated team monitors your network infrastructure round-the-clock, ensuring optimal performance, reliability, security and provide proactive support to address issues promptly. Our dedicated team ensures that your network remains operational and secure, minimizing downtime and maximizing productivity.'}
          maxLines={3}/>
        </div>

        <div className='whatcard'>
        <img src={admipicfour} alt='admipicfour'></img><br/><br/>
          <h2> Security and Compliance </h2>
          <ReadMore className='RMore'
          text={'Implement robust security measures to protect your cloud and on-premises environment infrastructure from cyber threats and ensure compliance with industry regulations and standards. From data encryption to access control, and from Firewall configuration to intrusion detection, we prioritize the security of your sensitive information and your network environment.'}
          maxLines={3}/>
        </div>

        <div className='whatcard'>
        <img src={admipicfive} alt='admipicfive'></img><br/><br/>
          <h2> Performance Optimization </h2>
          <ReadMore className='RMore'
          text={'Continuously optimize your on-premises network to enhance performance, efficiency, and reliability. Through regular maintenance, software updates, and performance tuning, we ensure that your infrastructure operates at peak performance levels.'}
          maxLines={3}/>
        </div>

        <div className='whatcard'>
        <img src={admipicsix} alt='admipicsix'></img><br/><br/>
          <h2> Cost Optimization </h2>
          <ReadMore className='RMore'
          text={'Optimize your cloud resources and minimize costs without compromising performance or security. We help you identify opportunities for cost savings through resource optimization, right-sizing, and strategic utilization monitoring.'}
          maxLines={3}/>
        </div>
      </div>
    </div>
  )
}

export default Entwhatwedo