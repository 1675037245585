import React from 'react'
import './Aimicapabilities.css'
// import { vector } from '../../../../../Assets/Images'
import {picone, pictwo, picthree, picfour, picfive, picsix} from '../../Servicespics/Colorpics'

const Aimicapabilities = () => {
  return (
    <div className='container'>

      <div className='innerborder'>
        {/* <img className='vectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='foryour1'>
        <br /><br /><br /> <h1> Capabilities</h1><br /><br /><br />
        </div>
      </div>

      <div className='cardcontainer'>
        <div className='card'>
        <img src={picone} alt='aimipicone'/><br/>
          <h2> Virtualized Computing</h2>
          <p>
          Leverage our advanced virtualization technologies to create and manage virtual machines on demand. Scale resources up or down based on your needs, optimizing performance without the hassle of physical hardware.
          </p>
        </div>

        <div className='card'>
        <img src={pictwo} alt='aimipictwo'/><br/>
          <h2> Storage Solution </h2>
          <p>
          Say goodbye to storage constraints. With our IaaS, access scalable and high-performance storage solutions for your data, whether it's structured databases or unstructured files.
          </p>
        </div>

        <div className='card'>
        <img src={picthree} alt='aimipicthree'/><br/>
          <h2> Land Balancing and Auto-Scaling </h2>
          <p>
          Keep your applications responsive even under heavy traffic. Our load balancing and auto-scaling features ensure that resources are distributed efficiently, maintaining performance without manual intervention.
          </p>
        </div>

        <div className='card'>
        <img src={picfour} alt='aimipicfour'/><br/>
          <h2>Backup and Disaster Recovery </h2>
          <p>
          Safeguard your data with automated backup and disaster recovery solutions. With regular backups and failover options, your business can remain resilient even in the face of unexpected events.
          </p>
        </div>

        <div className='card'>
        <img src={picfive} alt='aimipicfive'/><br/>
          <h2> Security Measures </h2>
          <p>
          We take security seriously. Our IaaS solutions incorporate advanced security features, including firewalls, encryption, and identity and access management, to protect your assets from threats.
          </p>
        </div>

        <div className='card'>
        <img src={picsix} alt='aimipicsix'/><br/>
          <h2> Cost Management</h2>
          <p>
          Achieve cost predictability with our pay-as-you-go pricing model. No need to invest in upfront hardware costs—pay only for the resources you use.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Aimicapabilities