import React from 'react'
import './Paascapabilities.css'
// import { vector } from '../../../../../Assets/Images'
import {picone, pictwo, picthree, picfour, picfive, picsix} from '../../Servicespics/Colorpics'

const Paascapabilities = () => {
  return (
    <div className='container'>

      <div className='innerborder'>
        {/* <img className='vectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='foryour1'>
        <br /><br /><br /><h1> Capabilities</h1><br /><br /><br />
        </div>
      </div>

      <div className='cardcontainer'>
        <div className='card'>
        <img src={picone} alt='paascapone'/><br/>
          <h2> Application Development </h2>
          <p>
          Leverage our PaaS environment to build, test, and deploy applications without the complexities of infrastructure management. Our tools and frameworks support a variety of programming languages and development methodologies.
          </p>
        </div>

        <div className='card'>
        <img src={pictwo} alt='paascaptwo'/><br/>
          <h2> Middleware Services  </h2>
          <p>
          Say goodbye to managing middleware components separately. Our PaaS solutions offer pre-configured middleware services such as databases, messaging systems, and caching, allowing you to focus on building features that matter.
          </p>
        </div>

        <div className='card'>
        <img src={picthree} alt='paascapthree'/><br/>
          <h2> Collaboration and Integration</h2>
          <p>
          Our PaaS platform fosters collaboration among development teams. With integrated development and deployment tools, your teams can work seamlessly to deliver high-quality applications.
          </p>
        </div>

        <div className='card'>
        <img src={picfour} alt='paascapfour'/><br/>
          <h2> Scalability and Auto-Scaling </h2>
          <p>
          Our PaaS environment automatically scales resources based on demand. Whether it's increasing instances during traffic spikes or decreasing them during lulls, your applications remain responsive and cost-efficient.
          </p>
        </div>

        <div className='card'>
        <img src={picfive} alt='paascapfive'/><br/>
          <h2> Security Measures   </h2>
          <p>
          Security is at the core of our PaaS solutions. Benefit from features such as secure data storage, authentication, and authorization mechanisms to protect your applications and data.
          </p>
        </div>

        <div className='card'>
        <img src={picsix} alt='paascapsix'/><br/>
          <h2> Monitoring and Analytics </h2>
          <p>
          Gain insights into application performance and user behavior with our monitoring and analytics tools. Make informed decisions to enhance user experience and optimize resource utilization.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Paascapabilities