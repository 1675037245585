import React, {useEffect} from 'react'
import aboutus from './Aboutus pics/aboutus.png'
import './Aboutus.css'
import Abouttech from './Abouttech'
import Ourvision from './Ourvision'
import Clients from '../Clients/Clients'
import Footer from '../Footer/Footer'
import { Partners } from '../Guide'
import Herotestimonials from '../Herotestimonials/Herotestimonials'
import Adddigitrans from '../Navbar/Services/Adddigitrans/Adddigitrans'


function Aboutus() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);
  return ( 
    <div>
      <div className='aboutheader'>
      <img src={aboutus} alt='aboutus'/>
      <h1> About Technacle  </h1>
      <p> Technacle specializes in technological and IT-related services such as Digital Transformation, Cloud Management, Infrastructure Support,
          Artificial Intelligence, etc. </p> 
      </div>   
      <br/>
      <Abouttech/>
      <br/><br/>
      <Herotestimonials/>
      <br/><br/><br/><br/>
      <Ourvision/>
      <Clients/>
      <Adddigitrans/>
      {/* <Testimonials/> */}
      <br/><br/><br/>
      <Partners/>
      <Footer/>
    </div>
  )
}

export default Aboutus

