import React, {useEffect} from 'react'
import './Home.css'
import {Slides, Info, Topics, Certification, Partners, Footer, Capabilities} from './Components/Guide'
import Herotestimonials from './Components/Herotestimonials/Herotestimonials'
import Clients from './Components/Clients/Clients'
import Adddigitrans from './Components/Navbar/Services/Adddigitrans/Adddigitrans'


function Home() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);
  return (
    <div>
        <Slides/>
        <Topics/>
        <br/><br/>
        <Herotestimonials/>
        <br/><br/><br/>
        <Info/>
        <Clients/>
        <br/>
        <Capabilities/>
        <br/>
        <Certification/>
        <br/>
        <Adddigitrans/>
        <Partners/>
        <br/>
        <Footer/>
    </div>
  )
}

export default Home