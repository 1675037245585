import React from 'react'
import './Aicapabilities.css'
// import { vector } from '../../../../../Assets/Images'
import {picone, pictwo, picthree, picfour, picfive, picsix} from '../../Servicespics/Colorpics'

const Cmcapabilities = () => {
  return (
    <div className='container'>

      <div className='innerborder'>
        {/* <img className='vectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='foryour1'>
        <br /><br /><br /><h1> Capabilities</h1><br /><br /><br />
        </div>
      </div>

      <div className='cardcontainer'>
        <div className='card'>
        <img src={picone} alt='mccapone'/><br/>
          <h2>Machine Learning </h2>
          <p>
            Leverage the prowess of machine learning algorithms to uncover patterns, make predictions, and derive insights from complex data sets. Our machine learning solutions empower data-driven decision-making like never before.
          </p>
          </div>

        <div className='card'>
        <img src={pictwo} alt='mccaptwo'/><br/>
          <h2> Natural Language Processing (NLP) </h2>
          <p>
           Communicate with technology in a natural, human-like way. Our NLP solutions enable sentiment analysis, language translation, chatbots, and more, enhancing customer interactions.
        </p>
        </div>

        <div className='card'>
        <img src={picthree} alt='mccapthree'/><br/>
          <h2> Computer Vision </h2>
          <p>
            Equip your systems with visual perception capabilities. Our computer vision solutions enable image recognition, object detection, and even autonomous navigation in various applications.
        </p>
        </div>

        <div className='card'>
        <img src={picfour} alt='mccapfour'/><br/>
          <h2> Predictive Analytics </h2>
          <p>
            Anticipate future trends and outcomes with predictive analytics. Our AI-powered models help you forecast demand, optimize resources, and stay ahead of the curve.
          </p>
          </div>

        <div className='card'>
        <img src={picfive} alt='mccapfive'/><br/>
          <h2> Robotic Process Automation (RPA) </h2>
          <p>
           Automate repetitive tasks and workflows with RPA. Free up your workforce from mundane tasks, allowing them to focus on high-value activities.
          </p>
        </div>

        <div className='card'>
        <img src={picsix} alt='mccapsix'/><br/>
          <h2> AI-Driven Insights </h2>
          <p>
           Transform data into actionable insights. Our AI solutions uncover hidden patterns and correlations, helping you make informed decisions and seize opportunities.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Cmcapabilities