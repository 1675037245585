import React from 'react'
import aboutam from './Pics/aboutpic.png'
import './Infraabout.css'

function Entabout() {
  return (
    <div className='entcontainer'>
    <div className='aboutent'>
        <h1>
        YOUR NETWORK MANAGEMENT STARTS HERE
        </h1><br/>
        <p>
        Embracing the cloud offers unparalleled scalability, flexibility, and agility for modern businesses. Our Cloud Managed Services empower you to harness the full potential of cloud technology while mitigating complexities and ensuring seamless operations. While the cloud offers undeniable benefits, some businesses prefer to maintain their infrastructure on-premises for various reasons, including compliance requirements or data sovereignty concerns. Our On-Premises Managed Services are tailored to meet the unique needs of such organizations, offering comprehensive support, reliability, and security.
        </p>
        <p>
        Whether you operate in the cloud, on-premises, or in a hybrid environment, our Network Managed Services are designed to optimize performance, enhance security, and drive efficiency across your network. We understand the critical role that a robust network infrastructure plays in the success of your business.
        </p>
    </div>
        <div className='aboutentpic'>
            <img src={aboutam} alt='aboutam'/>
        </div>
    </div>
  )
}

export default Entabout