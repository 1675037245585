import React, {useEffect} from 'react'
import aimicoverpic from '../ITinfradesign/Pics/it-infrastructure-design-implementation-and-support.png'
import './Itinfradesign.css'
import Infraabout from './Infraabout'
import Infracapabilities from './Infracapabilities'
import Clients from '../../../../Clients/Clients'
import Infrawhatwedo from './Infrawhatwedo'
import Adddigitrans from '../../Adddigitrans/Adddigitrans'
// import Testimonials from '../../.././../Svrtestimonials/Svrtestimonials'
import Footer from '../../../../Footer/Footer' 
import Herotestimonials from '../../../../Herotestimonials/Herotestimonials'
import Partners from '../../../../../Components/Partners/Partners'

function Itinfradesign() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);
  return ( 
    <div>
      <div className='itdsnheader'>
      <img src={aimicoverpic} alt='entcovrepic'/>
      <h1> IT Infrastructure Design, Implementation And Support </h1>
      <p>
      We pride ourselves on being your trusted partner in delivering comprehensive IT infrastructure solutions, spanning from initial design and seamless implementation to ongoing support and maintenance.
      </p> 
      </div>
      <Infraabout/>
      <br/><br/>
      <Herotestimonials/>
      <br/><br/><br/>
      <Infracapabilities/>
      <Clients/>
      <Infrawhatwedo/>
      <Adddigitrans/>
      <Partners/>
      {/* <Testimonials/> */}
      <br/><br/>
      <Footer/>   
    </div>
  )
}

export default Itinfradesign

