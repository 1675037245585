import React from 'react'
import './Infracapabilities.css'
// import { vector } from '../../../../../Assets/Images'
import {picone, pictwo, picthree, picfour, picfive, picsix} from '../../Servicespics/Colorpics'

const Infracapabilities = () => {
  return (
    <div className='entcapcontainer'>

      <div className='entcapinnerborder'>
        {/* <img className='entvectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='entcapforyour1'>
        <br /><br /><br /> <h1> Capabilities </h1><br /><br /><br />
        </div>
      </div>

      <div className='entcapcardcontainer'>
        <div className='entcapcard'>
        <img src={picone} alt='admipicone'/><br/>
          <h2> Expertise and Experience </h2>
          <p>
          With years of experience and a team of seasoned professionals, we have the expertise and knowledge to deliver IT infrastructure solutions that exceed your expectations.
          </p>
        </div>

        <div className='entcapcard'>
        <img src={pictwo} alt='admipictwo'/><br/>
          <h2> Customer-Centric Approach </h2>
          <p>
          We prioritize customer satisfaction above all else, working closely with you to understand your needs and deliver customized solutions that address your unique requirements.
          </p>
        </div>

        <div className='entcapcard'>
        <img src={picthree} alt='admipicthree'/><br/>
          <h2> Reliability and Security </h2>
          <p>
          We prioritize the reliability and security of your IT infrastructure, implementing industry best practices and robust security measures to mitigate risks and ensure compliance.
          </p>
        </div>

        <div className='entcapcard'>
        <img src={picfour} alt='admipicfour'/><br/>
          <h2> Innovation and Excellence </h2>
          <p>
          We are committed to innovation and excellence, continuously striving to deliver cutting-edge IT infrastructure solutions that drive business success and enable digital transformation.
          </p>
        </div>

        <div className='entcapcard'>
          <img src={picfive} alt='admipicfive'/><br/>
          <h2> Innovative Solutions </h2>
          <p>
          We deliver innovative solutions that drive business transformation and enable growth.
          </p>
        </div>

        <div className='entcapcard'>
        <img src={picsix} alt='admipicsix'/><br/>
          <h2> Agile and Scalable </h2>
          <p>
          We deliver innovative solutions that drive business transformation and enable growth.
          </p>
        </div>

        <div className='entcapcard'>
          <img src={picone} alt='admipicone'/><br/>
          <h2> Proactive Support </h2>
          <p>
          We provide proactive support and continuous optimization to maximize performance and reliability.
          </p>
        </div>

        <div className='entcapcard'>
        <img src={pictwo} alt='admipictwo'/><br/>
          <h2> Collaborative Partnership </h2>
          <p>
          We collaborate closely with you to deliver tailored solutions that meet your unique needs.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Infracapabilities