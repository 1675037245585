import React from 'react'
import './Paaswhatwedo.css'
// import { vector } from '../../../../../Assets/Images'
import {paascapone, paascaptwo, paascapthree, paascapfour, paascapfive, paascapsix} from './Paaspics/Paaspics'

const Whatwedopaas = () => {
  return (
    <div className='whatpaascontainer'>

      <div className='whatpaasinnerborder'>
        {/* <img className='whatpaasvectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='whatpaasforyour'>
        <br /><br /><br /><h1> What We Do </h1><br /><br /><br />
        </div>
      </div>

      <div className='whatpaascardcontainer'>
        <div className='whatpaascard'>
        <img src={paascapone} alt='paascapone'></img><br/><br/>
          <h2> Tailored Solutions </h2>
          <p>
          We understand that each business has unique requirements. Our experts work closely with you to design PaaS solutions that align with your development goals, enabling you to create applications that stand out.
          </p>
        </div>

        <div className='whatpaascard'>
        <img src={paascaptwo} alt='paascaptwo'></img><br/><br/>
          <h2> Onboard and Training </h2>
          <p>
          New to PaaS? No worries. Our onboarding and training programs help your teams get up to speed quickly, ensuring you harness the full potential of our platform.
          </p>
        </div>

        <div className='whatpaascard'>
        <img src={paascapthree} alt='paascapthree'></img><br/><br/>
          <h2> Optimization Performance </h2>
          <p>
          We're not just about deployment—we're about optimal deployment. Our team monitors and fine-tunes your applications for maximum performance, keeping your users engaged.
          </p>
        </div>

        <div className='whatpaascard'>
        <img src={paascapfour} alt='paascapfour'></img><br/><br/>
          <h2> 24/7 Support </h2>
          <p>
          Technical issues don't adhere to office hours. Our dedicated support team is available around the clock to address your concerns and provide rapid solutions.
          </p>
        </div>

        <div className='whatpaascard'>
        <img src={paascapfive} alt='paascapfive'></img><br/><br/>
          <h2> Innovation Enablement </h2>
          <p>
          We believe in innovation at every stage. Our PaaS solutions enable you to iterate quickly, experiment with new ideas, and deliver innovative solutions to market faster.
          </p>
        </div>

        <div className='whatpaascard'>
        <img src={paascapsix} alt='paascapsix'></img><br/><br/>
          <h2> Strategic Collaboration  </h2>
          <p>
          We're not just service providers; we're your partners in innovation. Count on us to provide insights, recommendations, and best practices to drive your application development journey.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Whatwedopaas