import React from 'react'
import './Aiwhatwedo.css'
// import { vector } from '../../../../../Assets/Images'
// import ReadMore from '../../../../ReadMore'
import {mccapone, mccaptwo,mccapthree, mccapfour, mccapfive, mccapsix} from './aipics/Mcpics'

const Aiwhatwedo = () => {
  return (
    <div className='whatcscontainer'>

      <div className='whatcsinnerborder'>
        {/* <img className='whatcsvectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='whatcsforyour1'>
        <br /><br /><br /><h1> What We Do </h1><br /><br /><br />
        </div>
      </div>

      <div className='whatcscardcontainer'>
        <div className='whatcscard'>
        <img src={mccapone} alt='mccapone'></img><br/><br/>
          <h2> Tailored Solutions </h2>
          <p>
          We understand that AI isn't a one-size-fits-all solution. Our experts collaborate with you to design AI strategies that align with your business objectives and challenges.
          </p>
        </div>

        <div className='whatcscard'>
        <img src={mccaptwo} alt='mccaptwo'></img><br/><br/>
          <h2> End-to-End Implementation </h2>
          <p>
          From conceptualization to deployment, we guide you through the entire AI journey. Our expertise spans from data preprocessing to model development and integration.
          </p>
        </div>

        <div className='whatcscard'>
        <img src={mccapthree} alt='mccapthree'></img><br/><br/>
          <h2> Continous Learning </h2>
          <p>
          AI is a journey of continuous improvement. Our solutions are designed to evolve and learn from new data, ensuring they remain effective and relevant over time.
          </p>
        </div>

        <div className='whatcscard'>
        <img src={mccapfour} alt='mccapfour'></img><br/><br/>
          <h2> Ethical AI </h2>
          <p>
          We prioritize ethical considerations in AI development. Our solutions adhere to responsible AI practices, ensuring fairness, transparency, and accountability.
          </p>
        </div>

        <div className='whatcscard'>
        <img src={mccapfive} alt='mccapfive'></img><br/><br/>
          <h2> Training and Adoption </h2>
          <p>
          Transitioning to AI doesn't have to be daunting. We offer training and support to ensure your teams are equipped to make the most of AI-driven technologies.
          </p>
        </div>

        <div className='whatcscard'>
        <img src={mccapsix} alt='mccapsix'></img><br/><br/>
          <h2> Strategic Collaboration </h2>
          <p>
          We're not just implementers; we're partners invested in your success. Count on us to provide insights, recommendations, and a roadmap for AI-powered growth.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Aiwhatwedo