import React from 'react'
import './Infracapabilities.css'
// import { vector } from '../../../../../Assets/Images'
import {picone, pictwo, picthree, picfour, picfive, picsix} from '../../Servicespics/Colorpics'
import ReadMore from '../../../../ReadMore'

const Infracapabilities = () => {
  return (
    <div className='entcapcontainer'>

      <div className='entcapinnerborder'>
        {/* <img className='entvectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='entcapforyour1'>
        <br /><br /><br /> <h1> Capabilities </h1><br /><br /><br />
        </div>
      </div>

      <div className='entcapcardcontainer'>
        <div className='entcapcard'>
        <img src={picone} alt='admipicone'/><br/>
          <h2> Server Maintenance </h2>
          <ReadMore className='pera'
          text={'Regularly monitoring and maintaining servers is essential for ensuring that web content is always available to users. This includes tasks like hardware maintenance, software updates, and security patches.'}
          maxLines={3}/>
        </div>

        <div className='entcapcard'>
        <img src={pictwo} alt='admipictwo'/><br/>
          <h2> Network Monitoring </h2>
          <ReadMore className='pera'
          text={'Ensuring that the network infrastructure is operating efficiently is important for website performance. Network monitoring helps identify issues like bandwidth bottlenecks, packet loss, and latency.'}
          maxLines={3}/>
        </div>

        <div className='entcapcard'>
        <img src={picthree} alt='admipicthree'/><br/>
          <h2> Database Management </h2>
          <ReadMore className='pera'
          text={'Databases store and manage website content, so it is essential to ensure they are functioning correctly. Database management includes tasks like data backup, replication, and optimization.'}
          maxLines={3}/>
        </div>

        <div className='entcapcard'>
        <img src={picfour} alt='admipicfour'/><br/>
          <h2> Security Measures </h2>
          <ReadMore className='pera'
          text={'Security is a critical aspect of IT infrastructure and maintenance for web content. Capabilities like firewalls, intrusion detection systems, and encryption help protect web content from unauthorized access, data loss, and cyber attacks.'}
          maxLines={3}/>
        </div>

        <div className='entcapcard'>
        <img src={picfive} alt='admipicfive'/><br/>
          <h2> Content Management Systems </h2>
          <ReadMore className='pera'
          text={'CMS platforms make it easy to manage and update website content. CMS capabilities like version control, content publishing workflows, and template management make it easy to maintain web content.'}
          maxLines={3}/>
        </div>

        <div className='entcapcard'>
        <img src={picsix} alt='admipicsix'/><br/>
          <h2> Analytics and Monitoring </h2>
          <ReadMore className='pera'
          text={'Tracking website traffic and user behavior is essential for optimizing web content. Analytics and monitoring capabilities provide insights into user engagement, search engine optimization, and website performance.'}
          maxLines={3}/>
        </div>
      </div>
    </div>
  )
}

export default Infracapabilities