import React from 'react'
import aboutmc from './Mcpics/Aboutmc.png'
import './Mcabout.css'

function Mcabout() {
  return (
    <div className='mccontainer'>
    <div className='aboutmc'>
        <h1>
        YOUR CLOUD MANAGEMENT STARTS HERE
        </h1><br/>
        <p>
        We understand that every business has unique requirements and objectives. Security is our top priority. We implement best-in-class security measures to protect your data, applications, and infrastructure in the cloud. From identity and access management to encryption and compliance auditing, we ensure that your cloud environment meets the highest security standards and regulatory requirements. That's why we take a personalized approach to design and implement cloud solutions that align with your specific needs, goals, and budget.
        </p>
        <p>
        Whether you're experiencing rapid growth or seasonal fluctuations in demand, our cloud solutions are designed to scale with your business. We leverage the elasticity of the cloud to ensure that your infrastructure can adapt to changing needs without overprovisioning or overspending.
        </p>
    </div>
        <div className='aboutmcpic'>
            <img src={aboutmc} alt='aboutmc'/>
        </div>
    </div>
  )
}

export default Mcabout