import React, {useEffect} from 'react'
import cloud from './Cmpics/cloud.png'
import './Cloudmanagement.css'
import Cmabout from './Cmabout'
import Cmcapabilities from './Cmcapabilities'
import Cmwhatwedo from './Cmwhatwedo'
import Herotestimonials from '../../../../Herotestimonials/Herotestimonials'
import Clients from '../../../../Clients/Clients'
import Partners from '../../../../../Components/Partners/Partners'
import Adddigitrans from '../../Adddigitrans/Adddigitrans'
import Footer from '../../../../Footer/Footer'


function Managedcloud() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);
  return ( 
    <div>
      <div className='mc-headercloud'>
      <img className='mcheaderimg' src={cloud} alt='cloud'/>

      <h1> Cloud Management Services </h1>
      <p>
      Envision with Technacle and plan ahead to deploy innovative business solutions for sustainable growth and success
      </p> 
      </div>
      <Cmabout/>
      <br/><br/>
      <Herotestimonials/>
      <br/><br/>
      <Cmcapabilities/>
      <Clients/>
      <Cmwhatwedo/>
      <Adddigitrans/>
      <Partners/>
      <Footer/>
    </div>
  )
}

export default Managedcloud

