import React from 'react'
import './Infracapabilities.css'
// import { vector } from '../../../../../Assets/Images'
import {picone, pictwo, picthree, picfour, picfive, picsix} from '../../Servicespics/Colorpics'

const Entcapabilities = () => {
  return (
    <div className='entcapcontainer'>

      <div className='entcapinnerborder'>
        {/* <img className='entvectorimg' src={vector} alt='vector' />
        <text>Discover Our Company</text><br /><br /><br /> */}

        <div className='entcapforyour1'>
        <br /><br /><br /><h1> Capabilities</h1><br /><br /><br />
        </div>
      </div>

      <div className='entcapcardcontainer'>
        <div className='entcapcard'>
        <img src={picone} alt='admipicone'/><br/>
          <h2> Expertise and Experience </h2>
          <p>
          With years of experience in the industry and a team of seasoned professionals, we possess the expertise and knowledge to deliver exceptional Managed Network Services tailored to your unique requirements.
          </p>
        </div>

        <div className='entcapcard'>
        <img src={pictwo} alt='admipictwo'/><br/>
          <h2> Customer-Centric Approach </h2>
          <p>
          We prioritize customer satisfaction and work closely with your team, striving to exceed your expectations by understanding your business objectives, challenges, and goals, delivering customized solutions that address your specific needs.
          </p>
        </div>

        <div className='entcapcard'>
        <img src={picthree} alt='admipicthree'/><br/>
          <h2> Innovation and Technology </h2>
          <p>
          We stay abreast of the latest technological advancements and industry trends , leveraging innovative solutions and cutting-edge technologies to drive continuous improvement and innovation.
          </p>
        </div>

        <div className='entcapcard'>
        <img src={picfour} alt='admipicfour'/><br/>
          <h2> Reliablity and Security </h2>
          <p>
          Our Managed Network Services are built on a foundation of reliability and security, and scalability, providing you with the peace of mind you need to focus on your core business activities.
          </p>
        </div>

        <div className='entcapcard'>
         <img src={picfive} alt='admipicfive'/><br/>
          <h2> Scalability and Flexibility </h2>
          <p>
          Whether you're a small startup or a large enterprise, our solutions are designed to scale with your business, providing the flexibility you need to grow and adapt in a rapidly evolving digital landscape.
          </p>
        </div>

        <div className='entcapcard'>
        <img src={picsix} alt='admipicsix'/><br/>
          <h2> Partnership and Collaboration</h2>
          <p>
          We view our relationship with clients as a partnership and collaborate closely with you to ensure that our solutions align with your business strategy and deliver tangible results.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Entcapabilities