import React, {useEffect} from 'react'
import aimicoverpic from '../Secuservice/Pics/managed-network-security-services.png'
import './Secuservices.css'
import Infraabout from './Infraabout'
import Infracapabilities from './Infracapabilities'
import Clients from '../../../../Clients/Clients'
import Infrawhatwedo from './Infrawhatwedo'
import Adddigitrans from '../../Adddigitrans/Adddigitrans'
// import Testimonials from '../../../../Svrtestimonials/Svrtestimonials'
import Footer from '../../../../Footer/Footer'
import Herotestimonials from '../../../../Herotestimonials/Herotestimonials'
import Partners from '../../../../../Components/Partners/Partners'

function Secuservices() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);
  return ( 
    <div className='managednetwork'>
      <div className='entheader'>
      <img src={aimicoverpic} alt='entcovrepic'/>

        <h1>Managed Network Security Services <br/>
        (Fortigate, Pfsense, Checkpoint, Palo Alto, Jupiter, Cisco, Forescout)</h1>
      <p>
      In today's digital landscape, safeguarding your network from ever-evolving cyber threats is paramount. Protect your network and safeguard your organization's.
      </p> 
      </div>
      <br/>
       <Infraabout/>
       <br/><br/>
       <Herotestimonials/>
       <br/><br/><br/>
      <Infracapabilities/>
      <Clients/>
      <Infrawhatwedo/>
      <Adddigitrans/>
      <Partners/>
      {/* <Testimonials/> */}
      <br/> <br/>
      <Footer/>
    </div>
  )
}

export default Secuservices

