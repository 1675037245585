import React from 'react'
import Stafing from '../Staffing/Staffingpics/staffing3.png'
import './Staffing.css'
import Aboutstaffing from './Aboutstaffing'
import Topicsstaffing from './Topicsstaffing'
import Whatwedostaffing from './Whatwedostaffing'
import Clients from '../../../Clients/Clients'
import Footer from '../../../Footer/Footer'
import Herotestimonials from '../../../Herotestimonials/Herotestimonials'
import Adddigitrans from '../Adddigitrans/Adddigitrans'
import Partners from '../../../../Components/Partners/Partners'
import { useEffect } from 'react'


function Staffing() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);
  return ( 
    <div>
      <div className='staf-header'>
      <img src={Stafing} alt='Stafing'/>

      <h1> STAFFING  </h1>
      <p>Specialized in providing top-notch tailored staffing solutions to meet the unique needs of your business, Technacle provides temporary, contract, or permanent talent with skilled professionals who will drive your organization's success. Our mission is to empower organizations to build high-performing teams by connecting them with exceptional talent.
      </p> 
      </div>   
       <Aboutstaffing/>
       <br/><br/><br/>
       <Herotestimonials/>
       <br/><br/><br/><br/>
       <Topicsstaffing/>
       <Clients/>
       <Whatwedostaffing/>
       <Adddigitrans/>
       <Partners/>
       <br/><br/>
       <Footer/>
    </div>
  )
}

export default Staffing

